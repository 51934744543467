import { WidgetCard } from "../../ui/view-elements/card-elements";
import React from "react";
import { Typography } from "@mui/material";

const KpiWidget = (props) => {
  const { header, value, additionalText } = props;

  return (
    <WidgetCard sx={{ backgroundColor: props.bgColor, width: "12rem" }}>
      <div style={{ display: "block" }}>
        <div
          style={{
            height: "40%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ padding: "0 0 0 0rem" }}>{header}</Typography>
        </div>
        <div>
          <Typography
            sx={{
              fontSize: "1.5rem",
              textAlign: "center",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {value}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <Typography variant="h7">
            {additionalText ? additionalText : ""}
          </Typography>
        </div>
      </div>
    </WidgetCard>
  );
};

export default KpiWidget;
