import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import InfoIcon from "@mui/icons-material/Info";
import { CustomWidthTooltip200 } from "../../ui/view-elements/CustomToolTips";
Chart.register(...registerables);

const DoughnutChart = (props) => {
  const { dataset, toolTipTitle } = props;

  if (!dataset) {
    return <h1>Loading chart data</h1>;
  }

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "left",
      },
      title: {
        display: true,
        text: "Verkauf pro aktiv Event",
        font: {
          size: 18,
        },
      },
      parsing: {
        key: "nested.value",
      },
    },
  };

  return (
    <div style={{ display: "flex", padding: "1rem" }}>
      <Doughnut type="doughnut" data={dataset.data} options={options} />
      <CustomWidthTooltip200 title={toolTipTitle} placement="left-start" arrow>
        <InfoIcon
          color="secondary"
          sx={{
            color: "#3471f5",
            "&: hover": {
              cursor: "pointer",
            },
          }}
        />
      </CustomWidthTooltip200>
    </div>
  );
};

export default DoughnutChart;
