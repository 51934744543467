import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { httpContext } from "../../lib/api/httpContext";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const EventListGrid = () => {
  const history = useHistory();
  const globalFilter = useSelector((state) => state.globalFilter);
  const eventsFilter = useSelector((state) => state.eventFilter);
  const [eventsData, setEventsData] = useState([]);
  const match = useMediaQuery((theme) => theme.breakpoints.up(1500));

  useEffect(() => {
    const statuses = `eventStatuses[]=${eventsFilter.eventStatus}`;
    const queries = `?hostId=${globalFilter.hostId}&${statuses}`;

    const getEvents = async () => {
      const response = await httpContext(`hosts/events${queries}`);

      if (response.ok) {
        const eventsData = await response.json();
        setEventsData(eventsData);
      }
    };
    getEvents();
  }, [
    eventsData.length,
    globalFilter.eventName,
    globalFilter.hostId,
    eventsFilter.eventStatus,
  ]);

  const columns = [
    {
      field: "eventName",
      headerName: "Name",
      width: match ? 220 : 170,
      editable: false,
    },
    {
      field: "eventStatus",
      headerName: "Status",
      width: 110,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "startDate",
      headerName: "Start",
      width: match ? 180 : 120,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "cityName",
      headerName: "Stadt",
      width: match ? 180 : 140,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "numberOfTickets",
      headerName: "Total Tickets",
      width: match ? 130 : 100,
      sortable: false,
      editable: false,
    },
    {
      field: "ticketsSales",
      headerName: "Tickets Verkauft",
      width: match ? 150 : 100,
      sortable: true,
      editable: false,
    },
    {
      field: "salesInPercent",
      headerName: "Verkauft in %",
      width: match ? 150 : 100,
      sortable: true,
      editable: false,
    },
  ];

  const rows = [];

  if (eventsData.length > 0) {
    eventsData.forEach((event) => {
      const startDate = new Date(event.startDate).toLocaleDateString("ch-CH", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });

      const salesInPercent = (
        (event.ticketSales / event.numberOfTickets) *
        100
      ).toFixed(2);

      rows.push({
        id: event.eventId,
        eventName: event.eventName,
        eventStatus: event.eventStatus,
        startDate: startDate,
        cityName: event.cityName,
        numberOfTickets: event.numberOfTickets,
        ticketsSales: event.ticketSales,
        salesInPercent: salesInPercent,
      });
    });
  }

  const rowClickHandler = (event) => {
    history.push(`/events/${event.id}`);
  };

  return (
    <DataGrid
      onRowClick={rowClickHandler}
      columns={columns}
      rows={rows}
      pageSize={10}
      sx={{
        padding: "0.5rem",
        margin: "1rem 3rem 0 0",
        borderWidth: "0px",
        height: "70vh",
        overflow: "hidden",
        boxShadow: (theme) => theme.shadowGroup.boxShadow,
        borderColor: (theme) => theme.palette.secondary.main,
        "& .MuiDataGrid-row:hover": {
          color: "secondary.main",
          cursor: "pointer",
        },
      }}
      disableSelectionOnClick
      rowsPerPageOptions={[10]}
    />
  );
};

export default EventListGrid;
