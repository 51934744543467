import { useEffect, useState } from "react";
import { httpContext } from "../../../lib/api/httpContext";
import HostItem from "../../host/dataDisplay/HostItem";

const RequestedHostList = () => {
  const [hostList, setHostList] = useState([]);

  useEffect(() => {
    const queryParam = "hosts?hostStatuses[]=approvalrequest";
    const getHostsRequestPending = async () => {
      const response = await httpContext(queryParam);

      if (response.ok) {
        const hostListData = await response.json();
        setHostList(hostListData);
      }
      if (!response.ok) {
        alert("failed to fetch the host list");
      }
    };

    getHostsRequestPending();
  }, [hostList.length]);

  return (
    <>
      {hostList.map((host, index) => (
        <HostItem
          key={index}
          host={host}
          hostList={hostList}
          setHostList={setHostList}
        />
      ))}
    </>
  );
};

export default RequestedHostList;
