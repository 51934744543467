export class CreateHostModel {
  constructor(name, hostEmail, phoneNumber, licenceType, address, hostUsers) {
    this.name = name;
    this.hostEmail = hostEmail;
    this.phoneNumber = phoneNumber;
    this.licenceType = licenceType;
    this.address = address;
    this.hostUsers = hostUsers;
  }
}
