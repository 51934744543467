import MessageItem from "./MessageItem";
import { useEffect, useState } from "react";
import { httpContext } from "../../lib/api/httpContext";
import SearchInputField from "../ui/navigation/SearchInputField";
import CustomDateRangePicker from "../ui/view-elements/DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "../../store/message-slice";

const MessageList = (props) => {
  const dispatch = useDispatch();
  const messageFilter = useSelector((state) => state.message);
  const filterDate = useSelector((state) => state.globalFilter);
  const [value, setValue] = useState([filterDate.dateFrom, filterDate.dateTo]);
  const { endpoint } = props;
  const [messageList, setMessageList] = useState([]);

  const senderSearchHandler = (event) => {
    dispatch(
      messageActions.setSenderFilter({
        sender: event.target.value,
      })
    );
  };

  const senderFilter = async () => {
    const queryParam = `messages/filtered?Sender=${messageFilter.sender}`;
    const response = await httpContext(queryParam);

    if (response.ok) {
      const messageData = await response.json();
      setMessageList(messageData);
    }
    if (!response.ok) {
      alert("Failed to filter the messages");
    }
  };

  useEffect(() => {
    if (messageFilter.sender.length > 2) {
      senderFilter();
      return;
    }
    const getMessages = async () => {
      const response = await httpContext(endpoint);

      if (response.ok) {
        const responseData = await response.json();
        setMessageList(responseData);
      }
      if (!response.ok) {
        alert("Failed to load the messages");
      }
    };
    getMessages();
  }, [messageList.length, messageFilter.sender]);

  return (
    <div style={{ width: "100%", marginTop: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "95%",
          margin: "0 0 1rem 0",
        }}
      >
        <SearchInputField
          placeHolder="Absender"
          onSearch={senderFilter}
          onChange={senderSearchHandler}
          defaultValue={messageFilter.sender}
        />
        <SearchInputField placeHolder="Host" />
        <CustomDateRangePicker value={value} setValue={setValue} />
      </div>
      {messageList.map((message, index) => (
        <MessageItem key={index} message={message} />
      ))}
    </div>
  );
};

export default MessageList;
