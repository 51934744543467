import CreateEventForm from "../events/forms/CreateEventForm";

const EventConfiguration = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "20vh",
        position: "sticky",
        top: "6rem",
      }}
    >
      <CreateEventForm
        label="Event erstellen"
        backgroundColor="transparent"
        padding="0rem 1rem  1rem 0rem"
      />
    </div>
  );
};

export default EventConfiguration;
