import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import userSlice from "./user-slice";
import hostSlice from "./host-slice";
import globalFilterSlice from "./global-filter-slice";
import eventSlice from "./event-slice";
import ticketSlice from "./ticket-slice";
import eventFilterSlice from "./event-filter-slice";
import uiSlice from "./ui-slice";
import messageSlice from "./message-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    host: hostSlice.reducer,
    globalFilter: globalFilterSlice.reducer,
    event: eventSlice.reducer,
    ticket: ticketSlice.reducer,
    eventFilter: eventFilterSlice.reducer,
    ui: uiSlice.reducer,
    eventTicket: eventSlice.reducer,
    message: messageSlice.reducer,
  },
});

export default store;
