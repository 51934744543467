import { useEffect, useState } from "react";
import { httpContext } from "../../../../lib/api/httpContext";
import { useParams } from "react-router-dom";
import EventTicketItem from "./EventTicketItem";
import { SubmitButtonFilled } from "../../../ui/inputs/input-components";
import { Modal, Typography } from "@mui/material";
import { CustomModal } from "../../../ui/view-elements/modal-elements";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../../store/ui-slice";
import CreateTicketForm from "./CreateTicketForm";

const EventTicketList = (props) => {
  const params = useParams();
  const { eventId } = props;
  const uiData = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const [ticketList, setTicketList] = useState([]);

  const closeModal = () => {
    dispatch(uiActions.closeCreateTicketModal());
  };

  const openModal = () => {
    dispatch(uiActions.openCreateTicketModal());
  };

  useEffect(() => {
    const getAllEventTickets = async () => {
      const response = await httpContext(`eventTickets/${params.eventId}`);

      if (response.ok) {
        const responseData = await response.json();
        setTicketList(responseData);
      }
      if (!response.ok) {
        alert("Failed to load the Tickets");
      }
    };
    getAllEventTickets();
  }, [eventId]);

  return (
    <>
      <div style={{ width: "100%" }}>
        {ticketList.map((ticket, index) => (
          <EventTicketItem key={index} ticket={ticket} />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <SubmitButtonFilled onClick={openModal}>
          <Typography>Neues Ticket</Typography>
        </SubmitButtonFilled>
      </div>
      <Modal open={uiData.ticketModal} onClose={closeModal}>
        <CustomModal>
          <CreateTicketForm eventId={eventId} />
        </CustomModal>
      </Modal>
    </>
  );
};

export default EventTicketList;
