import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventId: "",
  eventName: "",
  eventDescription: "",
  cityId: "",
  street: "",
  eventGenre: 1,
  entryPrice: undefined,
  startDate: "",
  endDate: "",
  numberOfTickets: undefined,
  eventTickets: [],
};

const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    createBasicInformation(state, action) {
      state.eventName = action.payload.eventName;
      state.eventDescription = action.payload.eventDescription;
      state.cityId = action.payload.cityId;
      state.street = action.payload.street;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.eventGenreId = action.payload.eventGenreId;
    },
    addCityToEvent(state, action) {
      state.cityId = action.payload.cityId;
    },
    resetEventForm(state) {
      state.eventName = "";
      state.eventDescription = "";
      state.cityId = "";
      state.street = "";
      state.eventGenre = 1;
      state.entryPrice = undefined;
      state.startDate = "";
      state.endDate = "";
      state.numberOfTickets = undefined;
      state.eventTickets = [];
    },
    setEventUpdateModel(state, action) {
      state.eventId = action.payload.eventId;
      state.eventName = action.payload.eventName;
      state.eventDescription = action.payload.eventDescription;
      state.eventGenres = action.payload.eventGenres;
      state.entryPrice = action.payload.entryPrice;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const eventActions = eventSlice.actions;

export default eventSlice;
