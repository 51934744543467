import { createTheme } from "@mui/material";

const mainPrimary = "#c6bcc5";
const shadowColor = "#4d4d4d";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#d1d9e3",
    },
    secondary: {
      main: "#754eff",
    },
  },
  shadowGroup: {
    boxShadow: `2px 5px 10px 2px ${shadowColor}`,
  },
});
