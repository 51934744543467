import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, TextField } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateRangePicker } from "@mui/lab";

const CustomDateRangePicker = (props) => {
  const { value, setValue } = props;
  return (
    <div style={{ minWidth: "30rem", marginLeft: "2rem" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText="Datum von"
          endText="Datum bis"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> bis </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDateRangePicker;
