import { createSlice } from "@reduxjs/toolkit";
import { removeFromLocalStorage } from "../lib/storage/localStorage";

const storedAuth = JSON.parse(window.localStorage.getItem("Elang-Admin"));

const initialAuthState = {
  isLoggedIn: !!storedAuth,
  id: storedAuth ? storedAuth.data.id : "",
  firstName: storedAuth ? storedAuth.data.firstName : "",
  lastName: storedAuth ? storedAuth.data.lastName : "",
  token: "",
  role: storedAuth ? storedAuth.data.role : "",
  hostId: storedAuth ? storedAuth.data.hostId : "",
  email: storedAuth ? storedAuth.data.email : "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
      removeFromLocalStorage("Elang-Admin");
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
