import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { useStyles } from "./EventsPage-styles";
import EventListGrid from "../../components/events/EventListGrid";
import EventsFilter from "../../components/events/EventsFilter";
const EventsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/events">
          Events
        </Link>
      </Breadcrumbs>
      <div className={classes.filterContainer}>
        <div className={classes.filter}>
          <EventsFilter />
        </div>
        <EventListGrid />
      </div>
    </div>
  );
};

export default EventsPage;
