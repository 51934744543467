import React, { useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Toolbar,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddIcon from "@mui/icons-material/Add";

import { drawerNavigation } from "../../../routes/page-navigation";
import { useHistory } from "react-router-dom";
import { httpContext } from "../../../lib/api/httpContext";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth-slice";
import { CustomModal } from "../view-elements/modal-elements";
import { useState } from "react";
import CreateUser from "../../user/CreateUser";
import CreateEventForm from "../../events/forms/CreateEventForm";
import { globalFilterActions } from "../../../store/global-filter-slice";
import AdminDashboardPage from "../../../pages/Admin/AdminDashboardPage";
import SecurityIcon from "@mui/icons-material/Security";
import MessageIcon from "@mui/icons-material/Message";
import { uiActions } from "../../../store/ui-slice";
import { theme } from "../../../theme/theme";

const SideDrawer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const uiData = useSelector((state) => state.ui);

  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

  const authData = useSelector((state) => state.auth);
  const [drawerNavigationState, setDrawerNavigationState] =
    useState(drawerNavigation);

  useEffect(() => {
    if (authData.role === "GodMode") {
      setDrawerNavigationState((prevState) => [
        ...prevState,
        {
          name: "GodMode",
          index: 4,
          link: "/admin",
          component: AdminDashboardPage,
          icon: SecurityIcon,
        },
      ]);
    }
  }, [authData.role]);

  const modalCloseHandler = () => {
    dispatch(uiActions.closeEventModal());
    dispatch(uiActions.closeUserModal());
  };

  const modalUserOpenHandler = () => {
    dispatch(uiActions.closeEventModal());
    dispatch(uiActions.openUserModal());
  };

  const modalEventOpenHandler = () => {
    dispatch(uiActions.openEventModal());
    dispatch(uiActions.closeUserModal());
  };

  const navItemClickHandler = (item) => {
    history.push(item.link);
  };

  const logoutHandler = async () => {
    const responseData = await httpContext("account/sign-out", "POST");

    if (responseData.ok) {
      dispatch(authActions.logout());
      dispatch(globalFilterActions.resetFilter());
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          boxSizing: "border-box",
          backgroundColor: (theme) => theme.palette.primary.main,
          zIndex: 1000,
          marginTop: "3rem",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {drawerNavigationState.map((item, index) => (
            <ListItem
              key={index}
              button
              disableRipple
              disableTouchRipple
              onClick={() => navItemClickHandler(item)}
            >
              <ListItemIcon>{<item.icon />}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
      <List sx={{ marginTop: "auto", marginBottom: "auto" }}>
        <ListItem
          button
          disableRipple
          disableTouchRipple
          onClick={() => history.push("/contact")}
        >
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText primary="Kontakt" />
        </ListItem>
        <ListItem
          button
          disableRipple
          disableTouchRipple
          onClick={modalUserOpenHandler}
        >
          <ListItemIcon>
            <PersonAddAltIcon />
          </ListItemIcon>
          <ListItemText primary="Benutzer erstellen" />
        </ListItem>
        <ListItem
          button
          disableRipple
          disableTouchRipple
          onClick={modalEventOpenHandler}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Event erstellen" />
        </ListItem>
      </List>
      <List sx={{ marginTop: "auto", marginBottom: "3rem" }}>
        <ListItem
          button
          disableRipple
          disableTouchRipple
          onClick={logoutHandler}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      <Modal
        onBackdropClick={modalCloseHandler}
        open={uiData.userModal}
        onClose={modalCloseHandler}
      >
        <CustomModal>
          <CreateUser exitIcon={true} onCloseModal={modalCloseHandler} />
        </CustomModal>
      </Modal>
      <Modal open={uiData.eventModal} onClose={modalEventOpenHandler}>
        <CustomModal>
          <div style={{ width: "50%", margin: "auto" }}>
            <CreateEventForm
              exitIcon={true}
              padding="0.5rem"
              onCloseModal={modalCloseHandler}
            />
          </div>
        </CustomModal>
      </Modal>
    </Drawer>
  );
};

export default SideDrawer;
