import {
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { GreenSwitch, InputContainer } from "../../ui/inputs/input-components";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/user-slice";

const HostUserCredentialRegistration = () => {
  const dispatch = useDispatch();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [accteptedAgb, setAcceptedAgb] = useState(false);

  const switchChangeHandle = () => {
    setAcceptedAgb((prevState) => !prevState);
  };

  const userCredentialsUpdate = () => {
    dispatch(
      userActions.createUserCredentials({
        email: emailInputRef.current.value,
        password: passwordInputRef.current.value,
        acceptedAgb: accteptedAgb,
      })
    );
  };

  return (
    <FormControl onChange={userCredentialsUpdate}>
      <FormGroup>
        <h3>Login Informationen</h3>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={emailInputRef}
            variant="filled"
            type="email"
            label="Kontakt Email"
          />
        </InputContainer>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            variant="filled"
            inputRef={passwordInputRef}
            label="Password"
            type="password"
          />
        </InputContainer>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            variant="filled"
            inputRef={passwordInputRef}
            label="Password wiederholen"
            type="password"
          />
        </InputContainer>
        <InputContainer>
          <FormGroup>
            <FormControlLabel
              control={
                <GreenSwitch
                  checked={accteptedAgb}
                  onChange={switchChangeHandle}
                />
              }
              label="Mit den AGBs einverstanden"
            />
          </FormGroup>
        </InputContainer>
      </FormGroup>
    </FormControl>
  );
};

export default HostUserCredentialRegistration;
