import RequestedHostList from "../../components/GodMode/Hosts/RequestedHostList";
import AllHostList from "../../components/GodMode/Hosts/AllHostList";

const HostAdminPage = (props) => {
  const { activeTab } = props;

  return (
    <div style={{ marginTop: "2rem" }}>
      {activeTab === 1 && <RequestedHostList />}
      {activeTab === 2 && <AllHostList />}
    </div>
  );
};

export default HostAdminPage;
