import React, { useEffect, useState } from "react";
import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Modal,
  Snackbar,
  Toolbar,
} from "@mui/material";
import SearchInputField from "./SearchInputField";
import CustomDateRangePicker from "../view-elements/DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { globalFilterActions } from "../../../store/global-filter-slice";
import { httpContext } from "../../../lib/api/httpContext";
import { CustomModal } from "../view-elements/modal-elements";
import HostModalList from "../../host/dataDisplay/HostModalList";
import ProfilePic from "../../../assets/pictures/famePic.jpeg";

const NavigationBar = () => {
  const authData = useSelector((state) => state.auth);
  const [authState, setAuthState] = useState(authData);
  const globalFilter = useSelector((state) => state.globalFilter);
  const dispatch = useDispatch();
  const [hostModalOpen, setHostModalOpen] = useState(false);
  const filterDate = useSelector((state) => state.globalFilter);
  const [value, setValue] = useState([filterDate.dateFrom, filterDate.dateTo]);
  const [hostList, setHostList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    setAuthState(authData);
    const dateFrom = new Date(value[0]).toLocaleDateString("en-US");
    const dateTo = new Date(value[1]).toLocaleDateString("en-US");
    dispatch(
      globalFilterActions.setDateRange({
        dateFrom: dateFrom,
        dateTo: dateTo,
      })
    );
  }, [value, authData.isLoggedIn]);

  const eventSearchHandler = (event) => {
    dispatch(
      globalFilterActions.setEventFilter({
        eventName: event.target.value,
      })
    );
  };

  const hostSearchHandler = (event) => {
    dispatch(
      globalFilterActions.setHostName({
        hostName: event.target.value,
      })
    );
  };

  const getHostList = async () => {
    const queryParam = `hosts?HostName=${globalFilter.hostName}`;
    const response = await httpContext(queryParam);

    if (response.ok) {
      const hostListData = await response.json();
      setHostList(hostListData);
      setHostModalOpen(true);
    }
    if (!response.ok) {
      alert("Failed to fetch hosts");
    }
  };

  const setHostId = async (hostId, hostName) => {
    dispatch(
      globalFilterActions.setHostId({
        hostId: hostId,
      })
    );
    dispatch(
      globalFilterActions.setHostName({
        hostName: hostName,
      })
    );
    setHostModalOpen(false);
  };

  const resetFilterHandler = () => {
    setShowSnackbar(true);
    dispatch(globalFilterActions.resetFilter());
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        color="success"
        onClose={handleClose}
        autoHideDuration={2000}
        message="Filter zurückgesetzt"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">Filter zurückgesetzt</Alert>
      </Snackbar>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          color="primary"
          sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        >
          <Toolbar>
            <Avatar
              alt="Profile Picture"
              src={ProfilePic}
              sx={{
                width: 85,
                height: 85,
                "&: hover": {
                  cursor: "pointer",
                },
                boxShadow: (theme) => theme.shadowGroup.boxShadow,
              }}
            />
            <div style={{ marginLeft: "5%" }} />
            {authState.role === "GodMode" && (
              <SearchInputField
                placeHolder="Host"
                onChange={hostSearchHandler}
                onSearch={getHostList}
                defaultValue={globalFilter.hostName}
              />
            )}
            <SearchInputField
              placeHolder="Event"
              onChange={eventSearchHandler}
              defaultValue={globalFilter.eventName}
            />
            <SearchInputField placeHolder="User" />
            <CustomDateRangePicker value={value} setValue={setValue} />
            <Button
              color="secondary"
              onClick={resetFilterHandler}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              Reset
            </Button>
            <div style={{ marginLeft: "5%" }} />
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{ marginTop: 70 }} />
      <Modal
        onBackdropClick={() => setHostModalOpen(false)}
        open={hostModalOpen}
        onClose={() => setHostModalOpen(false)}
      >
        <CustomModal>
          <HostModalList hostList={hostList} onClick={setHostId} />
        </CustomModal>
      </Modal>
    </>
  );
};

export default NavigationBar;
