import UserItem from "./UserItem";

const UserList = (props) => {
  const { hostUsers } = props;

  return (
    <div style={{ marginRight: "2rem" }}>
      <h1>Übersicht aller Nutzer</h1>
      {hostUsers.map((hostUser, index) => (
        <UserItem hostUser={hostUser} key={index} />
      ))}
    </div>
  );
};

export default UserList;
