import DashboardPage from "../pages/dashboard/DashboardPage";
import AnalyticsPage from "../pages/analytics/AnalyticsPage";
import EventsPage from "../pages/events/EventsPage";
import UserPage from "../pages/user/UserPage";
import SettingsPage from "../pages/settings/SettingsPage";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import FestivalIcon from "@mui/icons-material/Festival";
import BarChartIcon from "@mui/icons-material/BarChart";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventDetailPage from "../pages/events/EventDetailPage";
import UserDetailPage from "../pages/user/UserDetailPage";
import SecurityIcon from "@mui/icons-material/Security";
import AdminDashboardPage from "../pages/Admin/AdminDashboardPage";
import ContactPage from "../pages/contact/ContactPage";

export const drawerNavigation = [
  {
    name: "Dashboard",
    index: 0,
    link: "/dashboard",
    component: DashboardPage,
    icon: DashboardIcon,
  },
  {
    name: "Analytics",
    index: 1,
    link: "/analytics",
    component: AnalyticsPage,
    icon: BarChartIcon,
  },
  {
    name: "Events",
    index: 2,
    link: "/events",
    component: EventsPage,
    icon: FestivalIcon,
  },
  {
    name: "User",
    index: 3,
    link: "/users",
    component: UserPage,
    icon: PersonIcon,
  },
  {
    name: "Einstellungen",
    index: 4,
    link: "/settings",
    component: SettingsPage,
    icon: SettingsIcon,
  },
];

export const childPages = [
  {
    name: "Event Detail Page",
    link: "/events/:eventId",
    component: EventDetailPage,
  },
  {
    name: "User Detail Page",
    link: "/users/:userId",
    component: UserDetailPage,
  },
  {
    name: "Host Admin",
    link: "/admin",
    component: AdminDashboardPage,
  },
  {
    name: "Kontakt",
    link: "/contact",
    component: ContactPage,
  },
];
