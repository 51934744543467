import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MessageItem = (props) => {
  const { message } = props;

  return (
    <Accordion
      sx={{
        backgroundColor: "transparent",
        width: "95%",
        margin: "0 0 1rem 0",
        boxShadow: (theme) => theme.shadowGroup.boxShadow,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "30%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography color="secondary" variant="h7">
                  Absender
                </Typography>
              </Grid>
              <Grid item container>
                <Typography variant="h6">
                  {message.firstName} {message.lastName}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: "30%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography color="secondary" variant="h7">
                  Kategorie
                </Typography>
              </Grid>
              <Grid item container>
                <Typography variant="h6">{message.messageCategory}</Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: "30%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography color="secondary" variant="h7">
                  Datum
                </Typography>
              </Grid>
              <Grid item container>
                <Typography variant="h5">
                  {new Date(message.createdDate).toLocaleDateString("ch-CH", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: "30%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography color="secondary" variant="h7">
                  Status
                </Typography>
              </Grid>
              <Grid item container>
                <Typography variant="h5">{message.messageStatus}</Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid>
          <Grid item container spacing={3}>
            <Grid item container>
              <Typography variant="h5" color="secondary">
                {message.header}
              </Typography>
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant="h6">{message.messageBody}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MessageItem;
