import { styled } from "@mui/material";

export const WidgetCard = styled("div")(({ theme }) => ({
  borderRadius: "10px",
  boxShadow: theme.shadowGroup.boxShadow,
  minHeight: "8rem",
  maxHeight: "8rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "2rem",
  maxWidth: "20rem",
}));
