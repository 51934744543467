import React, { useState } from "react";
import { Fab, FormControl, Typography } from "@mui/material";
import {
  ButtonOutlined,
  SubmitButtonFilled,
} from "../../ui/inputs/input-components";
import EventBasicInformation from "./EventBasicInformation";
import EventTicketInformation from "./EventTicketInformation";
import { useDispatch, useSelector } from "react-redux";
import { CreateEventModel } from "../../../models/events/create-event";
import { httpContext } from "../../../lib/api/httpContext";
import { theme } from "../../../theme/theme";
import { eventActions } from "../../../store/event-slice";
import { ticketActions } from "../../../store/ticket-slice";
import CancelIcon from "@mui/icons-material/Cancel";

const CreateEventForm = (props) => {
  const dispatch = useDispatch();
  const { backgroundColor, padding, label, exitIcon } = props;
  const { onCloseModal } = props;
  const globalFilter = useSelector((state) => state.globalFilter);
  const eventData = useSelector((state) => state.event);
  const eventTicket = useSelector((state) => state.ticket);
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const backStep = () => {
    setStep((prevState) => prevState - 1);
  };

  const submitHandler = async () => {
    const eventTickets = [eventTicket];
    const eventModel = new CreateEventModel(
      eventData.eventName,
      eventData.eventDescription,
      globalFilter.hostId,
      eventData.cityId,
      eventData.street,
      eventData.eventGenreId,
      eventData.entryPrice,
      eventData.startDate,
      eventData.eventStatuses,
      eventData.eventGenre,
      eventData.endDate,
      220,
      eventTickets
    );

    const response = await httpContext("events", "POST", eventModel);

    if (response.ok) {
      setStep(0);
      dispatch(eventActions.resetEventForm());
      dispatch(ticketActions.resetEventTicketForm());
      onCloseModal();
      window.location.reload();
    }
    if (!response.ok) {
      alert("Failed to create the event");
    }
  };

  return (
    <FormControl
      sx={{
        backgroundColor: backgroundColor ? backgroundColor : "white",
        borderRadius: "10px",
        padding: padding ? padding : "1rem",
        width: "30rem",
        [theme.breakpoints.down(1465)]: {
          width: "100%",
        },
      }}
    >
      {exitIcon && (
        <Fab
          size="small"
          aria-label="add"
          color="secondary"
          sx={{ marginLeft: "auto" }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={onCloseModal}
        >
          <CancelIcon />
        </Fab>
      )}
      <Typography align="center" color="secondary" variant="h5">
        Neuen Event erstellen
      </Typography>
      {step === 0 && <EventBasicInformation />}
      {step === 1 && <EventTicketInformation />}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {step > 0 && (
          <ButtonOutlined onClick={backStep}>
            <Typography>Back</Typography>
          </ButtonOutlined>
        )}
        {step === 0 && (
          <SubmitButtonFilled onClick={nextStep}>Next</SubmitButtonFilled>
        )}
        {step === 1 && (
          <SubmitButtonFilled onClick={submitHandler}>
            Submit
          </SubmitButtonFilled>
        )}
      </div>
    </FormControl>
  );
};

export default CreateEventForm;
