import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventTicketUpdate from "./EventTicketUpdate";
import { Pie, PolarArea, Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import { httpContext } from "../../../../lib/api/httpContext";
import { CustomModal } from "../../../ui/view-elements/modal-elements";
import {
  ButtonOutlined,
  SubmitButtonFilled,
} from "../../../ui/inputs/input-components";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../../store/ui-slice";

const options = {
  response: true,
  plugins: {
    legend: {
      display: false,
      position: "left",
    },
  },
};

const EventTicketItem = (props) => {
  const { ticket } = props;
  const [eventTicketAnalytics, setEventTicketAnalytics] = useState();
  const uiData = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = `?EventTicketId=${ticket.eventTicketId}`;
    const getEventTicketAnalytics = async () => {
      const response = await httpContext(`analytics/eventTickets${query}`);

      if (response.ok) {
        const responseData = await response.json();
        setEventTicketAnalytics({
          labels: ["Männer", "Frauen"],
          datasets: [
            {
              label: "Geschlechter Verteilung",
              data: [
                responseData.maleInPercent.toFixed(2),
                responseData.femaleInPercent.toFixed(2),
              ],
              backgroundColor: [
                `rgb(54, 162, 235, 0.7)`,
                `rgb(255, 99, 132, 0.7)`,
              ],
              offset: 2,
              borderWidth: 0,
            },
          ],
        });
      }
    };
    getEventTicketAnalytics();
  }, [ticket.eventTicketId]);

  const deleteEventTicketHandler = async () => {
    const deleteResponse = await httpContext(
      `eventTickets/${ticket.eventTicketId}`,
      "DELETE"
    );

    if (!deleteResponse.ok) {
      alert("Deleting EventTicket failed");
    }
    if (deleteResponse.ok) {
      window.location.reload();
      alert("Ticket wurde gelöscht!");
    }
  };

  const openDeleteModal = () => {
    dispatch(uiActions.openDeleteModal());
  };

  const closeModalHandler = () => {
    dispatch(uiActions.closeDeleteModal());
  };

  return (
    <>
      <Accordion
        sx={{
          margin: "0 0 1rem 0",
          borderRadius: "8px",
          backgroundColor: (theme) => theme.palette.primary.main,
          boxShadow: "2px 2px 6px 2px black",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              margin: "0 2rem 0 2rem",
            }}
          >
            <Typography>Preis: CHF {ticket.price.toFixed(2)}</Typography>
            <Typography>Kategorie: {ticket.ticketCategory}</Typography>
            <Typography color="secondary">
              Verbleibende Tickets: {ticket.numberOfAvailableTickets}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EventTicketUpdate ticket={ticket} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {eventTicketAnalytics && (
                <Pie
                  type="pie"
                  data={eventTicketAnalytics}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "Verteilung nach Geschlecht",
                        font: {
                          size: 18,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
            <IconButton
              sx={{ fontSize: "3rem" }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={openDeleteModal}
            >
              <RemoveCircleOutlineIcon
                sx={{ color: "red", fontSize: "3rem" }}
              />
            </IconButton>
          </div>
          {eventTicketAnalytics && (
            <Accordion
              sx={{ backgroundColor: "transparent", marginTop: "1rem" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ display: "flex" }}
              >
                <BarChartIcon color="secondary" sx={{ marginRight: "2rem" }} />
                <Typography fontSize="h6">Weitere Analytics Daten</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    padding: "0.5rem",
                    width: "90%",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <PolarArea
                      type="polarArea"
                      data={eventTicketAnalytics}
                      options={options}
                    />
                  </div>
                  <div style={{ width: "40%" }}>
                    <PolarArea data={eventTicketAnalytics} options={options} />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Bar type="bar" data={eventTicketAnalytics} />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </AccordionDetails>
      </Accordion>
      <Modal open={uiData.deleteModal} onClose={closeModalHandler}>
        <CustomModal>
          <div
            style={{
              padding: "2rem",
              borderRadius: "8px",
              width: "20%",
              backgroundColor: "white",
            }}
          >
            <div style={{ padding: "2rem" }}>
              <Typography align="center" variant="h4">
                Unwiederruflich löschen?
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "auto",
              }}
            >
              <ButtonOutlined onClick={closeModalHandler}>
                Cancel
              </ButtonOutlined>
              <SubmitButtonFilled
                onClick={deleteEventTicketHandler}
                style={{
                  color: "white",
                  fontSize: "1.6rem",
                  backgroundColor: "red",
                }}
              >
                Delete
              </SubmitButtonFilled>
            </div>
          </div>
        </CustomModal>
      </Modal>
    </>
  );
};

export default EventTicketItem;
