import { FormControl, FormGroup, Typography } from "@mui/material";
import { useState } from "react";
import HostBasicInformationStep from "./HostBasicInformationStep";
import {
  ButtonOutlined,
  SubmitButtonFilled,
} from "../../ui/inputs/input-components";
import HostUserRegistration from "./HostUserRegistration";
import HostUserCredentialRegistration from "./HostUserCredentialRegistration";
import { useDispatch, useSelector } from "react-redux";
import { hostActions } from "../../../store/host-slice";
import { httpContext } from "../../../lib/api/httpContext";
import { CreateHostModel } from "../../../models/host/create-host-model";
import { useHistory } from "react-router-dom";
import CustomStepper from "../../ui/view-elements/CustomStepper";

const CreateHostForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hostData = useSelector((state) => state.host);
  const userData = useSelector((state) => state.user);
  const [registrationStep, setRegistrationStep] = useState(0);

  const nextHandler = () => {
    setRegistrationStep((prevState) => prevState + 1);
  };

  const backHandler = () => {
    setRegistrationStep((prevState) => prevState - 1);
  };

  const submitHostData = async () => {
    const hostUsers = [{ user: userData }];
    dispatch(
      hostActions.addHostUsers({
        hostUsers: hostUsers,
      })
    );

    const hostModel = new CreateHostModel(
      hostData.name,
      hostData.email,
      hostData.phoneNumber,
      hostData.licenceType,
      hostData.address,
      hostUsers
    );

    const response = await httpContext("hosts", "POST", hostModel);

    if (!response.ok) {
      alert("failed to create host");
    }
    if (response.ok) {
      dispatch(hostActions.resetHostState());
      history.replace("/login");
    }
  };

  const backToLoginHandler = () => {
    history.push("/login");
  };

  const steps = ["Basis", "User", "Login"];

  return (
    <FormControl>
      <CustomStepper steps={steps} activeStep={registrationStep} />
      <h1>Registrierungs Antrag</h1>
      {registrationStep === 0 && <HostBasicInformationStep />}
      {registrationStep === 1 && <HostUserRegistration />}
      {registrationStep === 2 && <HostUserCredentialRegistration />}

      {registrationStep === 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ButtonOutlined onClick={backToLoginHandler}>
            <Typography>Zurück zum Login</Typography>
          </ButtonOutlined>
          <SubmitButtonFilled onClick={nextHandler}>Next</SubmitButtonFilled>
        </div>
      )}
      {registrationStep === 1 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ButtonOutlined onClick={backHandler}>
            <Typography>Zurück</Typography>
          </ButtonOutlined>
          <SubmitButtonFilled onClick={nextHandler}>Next</SubmitButtonFilled>
        </div>
      )}
      {registrationStep === 2 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ButtonOutlined onClick={backHandler}>
            <Typography>Zurück</Typography>
          </ButtonOutlined>
          <SubmitButtonFilled onClick={submitHostData}>
            Submit
          </SubmitButtonFilled>
        </div>
      )}
    </FormControl>
  );
};

export default CreateHostForm;
