import {
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { InputContainer } from "../../ui/inputs/input-components";
import { useEffect, useRef, useState } from "react";
import { httpContext } from "../../../lib/api/httpContext";
import { useDispatch, useSelector } from "react-redux";
import hostSlice, { hostActions } from "../../../store/host-slice";
import { CreateAddress } from "../../../models/address/create-address";

const HostBasicInformationStep = () => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("");
  const [selectedLicenceId, setSelectedLicenceId] = useState(0);
  const [query, setQuery] = useState("");
  const hostNameInputRef = useRef();
  const hostEmailInputRef = useRef();
  const streetInputRef = useRef();
  const phoneInputRef = useRef();

  const cityParamHandler = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const citySearchHandler = async () => {
      if (query.length < 3) {
        return;
      }

      const queryParam = "cityName=" + query;

      const response = await httpContext("cities/filter?" + queryParam);

      if (response.ok) {
        const cityData = await response.json();
        setCities(cityData);
      }
      if (!response.ok) {
        alert("Failed to fetch cities");
      }
    };
    citySearchHandler();
  }, [cities.length, query]);

  const citySelectHandler = (event) => {
    setSelectedCityId(event.target.value);
    dispatch(
      hostActions.addCityToHost({
        cityId: event.target.value,
      })
    );
  };

  const licenceSelectHandler = (event) => {
    setSelectedLicenceId(event.target.value);
    dispatch(
      hostActions.setLicenceType({
        licenceType: event.target.value,
      })
    );
  };

  const updateHostData = () => {
    dispatch(
      hostActions.createHost({
        name: hostNameInputRef.current.value,
        email: hostEmailInputRef.current.value,
        street: streetInputRef.current.value,
        cityId: selectedCityId,
        phoneNumber: phoneInputRef.current.value,
        licenceType: selectedLicenceId,
      })
    );
  };

  return (
    <FormControl onChange={updateHostData}>
      <FormGroup>
        <h3>Basis Informationen</h3>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={hostNameInputRef}
            variant="filled"
            type="text"
            label="Host Name"
          />
        </InputContainer>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={hostEmailInputRef}
            variant="filled"
            type="email"
            label="Kontakt Email"
          />
        </InputContainer>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={phoneInputRef}
            variant="filled"
            type="number"
            label="Telefon"
          />
        </InputContainer>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={streetInputRef}
            variant="filled"
            type="text"
            label="Strasse"
          />
        </InputContainer>
        <InputContainer>
          <Select
            sx={{ width: "100%" }}
            variant="filled"
            value={selectedLicenceId}
            onChange={licenceSelectHandler}
          >
            <MenuItem value={0}>Standard</MenuItem>
            <MenuItem value={1}>Pro</MenuItem>
            <MenuItem value={2}>Enterprise</MenuItem>
          </Select>
          <FormHelperText
            sx={{ color: (theme) => theme.palette.secondary.main }}
          >
            Wähle die Lizenz aus
          </FormHelperText>
        </InputContainer>
        <InputContainer>
          <Select
            sx={{ width: "100%" }}
            variant="filled"
            value={selectedCityId}
            onChange={citySelectHandler}
          >
            <TextField
              sx={{ width: "100%" }}
              onChange={cityParamHandler}
              variant="filled"
              label="PLZ Stadt"
              type="text"
              helperText="Suche nach Ortschaft"
            />
            {cities.map((city, index) => (
              <MenuItem key={index} value={city.id}>
                {city.zipCode} {city.cityName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>PLZ und Ortschaft</FormHelperText>
        </InputContainer>
      </FormGroup>
    </FormControl>
  );
};

export default HostBasicInformationStep;
