export class CreateUserModel {
  constructor(
    firstName,
    lastName,
    email,
    gender,
    birthday,
    password,
    acceptedAgb,
    roleId,
    address
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.gender = gender;
    this.birthday = birthday;
    this.password = password;
    this.acceptedAgb = acceptedAgb;
    this.roleId = roleId;
    this.address = address;
  }
}
