import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalOpen: false,
  eventModal: false,
  userModal: false,
  ticketModal: false,
  deleteModal: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    openEventModal(state) {
      state.modalOpen = true;
      state.eventModal = true;
    },
    closeEventModal(state) {
      state.modalOpen = false;
      state.eventModal = false;
    },
    openUserModal(state) {
      state.modalOpen = true;
      state.userModal = true;
    },
    closeUserModal(state) {
      state.modalOpen = false;
      state.userModal = false;
    },
    openCreateTicketModal(state) {
      state.modalOpen = true;
      state.ticketModal = true;
    },
    closeCreateTicketModal(state) {
      state.modalOpen = false;
      state.ticketModal = false;
    },
    openDeleteModal(state) {
      state.modalOpen = true;
      state.deleteModal = true;
    },
    closeDeleteModal(state) {
      state.modalOpen = false;
      state.deleteModal = false;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
