import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ContactForm from "../../components/contact/ContactForm";
import MessageInbox from "../../components/contact/MessageInbox";
import { useSelector } from "react-redux";

const ContactPage = () => {
  const [tabValue, setTabValue] = useState(0);

  const tabChangeHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={tabChangeHandler}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          value={0}
          label="Nachrichten"
          disableRipple
          disableTouchRipple
          disableFocusRipple
        />
        <Tab
          value={3}
          label="Archiv"
          disabled
          disableRipple
          disableTouchRipple
          disableFocusRipple
        />
      </Tabs>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2rem 2rem 0 0",
        }}
      >
        <div>
          <ContactForm />
        </div>
        <div style={{ marginLeft: "2rem", width: "100%" }}>
          {tabValue === 0 && <MessageInbox />}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
