import { Pie } from "react-chartjs-2";
import React from "react";

const CustomPieChart = (props) => {
  const { data, options } = props;

  return <Pie type="pie" data={data} options={options} />;
};

export default CustomPieChart;
