import { Box } from "@mui/system";
import { Step, StepLabel, Stepper } from "@mui/material";
import { CustomStep, useStyles } from "./stepper-elements";

const CustomStepper = (props) => {
  const { steps, activeStep } = props;
  const classes = useStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        orientation="horizontal"
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label} classes={{ root: classes.root }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
