import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoginPage from "../pages/Authentication/LoginPage";
import RegistrationPage from "../pages/registration/RegistrationPage";

const UnauthorizedRoutes = () => {
  return (
    <Switch>
      <Route path="/login" exact={true} component={LoginPage} />
      <Route path="/registration" exact={true} component={RegistrationPage} />
      <Route path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};

export default UnauthorizedRoutes;
