import React, { useState } from "react";
import { Fab, FormControl, Typography } from "@mui/material";
import {
  ButtonOutlined,
  SubmitButtonFilled,
} from "../ui/inputs/input-components";
import { CreateAddress } from "../../models/address/create-address";
import { CreateUserModel } from "../../models/users/create-user";
import { httpContext } from "../../lib/api/httpContext";
import { CreateHostUser } from "../../models/host-user/create-hostUser";
import UserPersonalData from "./UserPersonalData";
import LoginCredentials from "./LoginCredentials";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/user-slice";
import CancelIcon from "@mui/icons-material/Cancel";

const CreateUser = (props) => {
  const globalFilter = useSelector((state) => state.globalFilter);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { onCloseModal, exitIcon } = props;

  const [createUserStep, setCreateUserStep] = useState(0);

  const [gender, setGender] = useState(0);

  const selectGenderHandler = (event) => {
    setGender(event.target.value);
  };

  const nextStepHandler = () => {
    setCreateUserStep((prevState) => prevState + 1);
  };

  const previousStepHandler = () => {
    setCreateUserStep((prevState) => prevState - 1);
  };

  const submitHandler = async () => {
    const address = new CreateAddress(
      "Leymenstrasse 1",
      "c1234567-8003-43c1-92a4-bdc76a7c5dde"
    );

    const user = new CreateUserModel(
      userData.firstName,
      userData.lastName,
      userData.email,
      userData.gender,
      userData.birthday,
      userData.password,
      userData.acceptedAgb,
      userData.userRole,
      address
    );

    const hostUser = new CreateHostUser(globalFilter.hostId, user);

    await httpContext("hostUsers", "POST", hostUser);

    onCloseModal();
    dispatch(userActions.clearUserDataState());
  };

  const cancelHandler = () => {
    onCloseModal();
  };

  return (
    <FormControl
      sx={{
        backgroundColor: "white",
        marginTop: "3rem",
        borderRadius: "10px",
        padding: "1rem",
        width: "30rem",
        boxShadow: (theme) => theme.shadowGroup.boxShadow,
      }}
    >
      {exitIcon && (
        <Fab
          size="small"
          aria-label="add"
          color="secondary"
          sx={{ marginLeft: "auto" }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={onCloseModal}
        >
          <CancelIcon />
        </Fab>
      )}
      <Typography
        align="center"
        color="secondary"
        variant="h5"
        sx={{ marginBottom: "1rem" }}
      >
        Benutzer erstellen
      </Typography>
      {createUserStep === 0 && (
        <UserPersonalData
          gender={gender}
          selectGenderHandler={selectGenderHandler}
        />
      )}
      {createUserStep === 1 && <LoginCredentials />}

      <div style={{ display: "flex" }}>
        {createUserStep === 0 && (
          <>
            <ButtonOutlined onClick={cancelHandler}>
              <Typography variant="h6">Cancel</Typography>
            </ButtonOutlined>
            <SubmitButtonFilled
              onClick={nextStepHandler}
              style={{ marginLeft: "auto" }}
            >
              <Typography variant="h6">Next</Typography>
            </SubmitButtonFilled>
          </>
        )}
        {createUserStep === 1 && (
          <>
            <ButtonOutlined
              onClick={previousStepHandler}
              style={{ marginLeft: "auto" }}
            >
              <Typography variant="h6">Zurück</Typography>
            </ButtonOutlined>
            <SubmitButtonFilled
              onClick={submitHandler}
              style={{ marginLeft: "auto" }}
            >
              <Typography variant="h6">Submit</Typography>
            </SubmitButtonFilled>
          </>
        )}
      </div>
    </FormControl>
  );
};

export default CreateUser;
