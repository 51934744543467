import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { childPages, drawerNavigation } from "./page-navigation";
import { useSelector } from "react-redux";
import AdminDashboardPage from "../pages/Admin/AdminDashboardPage";
import SecurityIcon from "@mui/icons-material/Security";

const PageRoutes = () => {
  const authData = useSelector((state) => state.auth);
  const [drawerNavigationState, setDrawerNavigationState] =
    useState(drawerNavigation);

  useEffect(() => {
    if (authData.role === "GodMode") {
      setDrawerNavigationState((prevState) => [
        ...prevState,
        {
          name: "GodMode",
          index: 4,
          link: "/admin/:category",
          component: AdminDashboardPage,
          icon: SecurityIcon,
        },
      ]);
    }
  }, [authData.role]);

  return (
    <Switch>
      {drawerNavigationState.map((item, index) => (
        <Route
          key={index}
          path={item.link}
          exact={true}
          component={item.component}
        />
      ))}
      {childPages.map((item, index) => (
        <Route key={index} path={item.link} component={item.component} />
      ))}
      <Route path="/">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
};

export default PageRoutes;
