import React from "react";
import { Line } from "react-chartjs-2";
import { CustomWidthTooltip200 } from "../../ui/view-elements/CustomToolTips";
import InfoIcon from "@mui/icons-material/Info";
import { getGradient } from "./gradient-style";

const LineChart = (props) => {
  const { dataset, labels, toolTipTitle } = props;
  if (!dataset) {
    return <p>Loading...</p>;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: dataset.label,
        data: dataset.data,
        fill: true,
        borderWidth: "1.1px",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        tension: 0.3,
      },
    ],
  };

  return (
    <div style={{ display: "flex" }}>
      <Line data={data} type="line" />
      <CustomWidthTooltip200 title={toolTipTitle} placement="left-start" arrow>
        <InfoIcon
          sx={{
            color: "#3471f5",
            "&: hover": {
              cursor: "pointer",
            },
          }}
        />
      </CustomWidthTooltip200>
    </div>
  );
};

export default LineChart;
