import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sender: "",
  host: "",
  messageCategory: 0,
  header: "",
  messageBody: "",
};

const messageSlice = createSlice({
  name: "message",
  initialState: initialState,
  reducers: {
    createMessage(state, action) {
      state.header = action.payload.header;
      state.messageBody = action.payload.messageBody;
    },
    setMessageCategory(state, action) {
      state.messageCategory = action.payload.messageCategory;
    },
    setSenderFilter(state, action) {
      state.sender = action.payload.sender;
    },
    setHostFilter(state, action) {
      state.host = action.payload.host;
    },
  },
});

export const messageActions = messageSlice.actions;

export default messageSlice;
