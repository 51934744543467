export class UpdateEventModel {
  constructor(
    eventId,
    eventName,
    eventDescription,
    eventGenres,
    entryPrice,
    startDate,
    endDate,
    eventStatus
  ) {
    this.eventId = eventId;
    this.eventName = eventName;
    this.eventDescription = eventDescription;
    this.eventGenres = eventGenres;
    this.entryPrice = entryPrice;
    this.startDate = startDate;
    this.endDate = endDate;
    this.eventStatus = eventStatus;
  }
}
