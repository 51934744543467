import {
  Alert,
  FormControl,
  FormGroup,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  DangerButton,
  InputContainer,
  InputGroup,
  SubmitButtonFilled,
  WarningButtonOutlined,
} from "../../ui/inputs/input-components";
import { useRef, useState } from "react";
import { UpdateHostUserModel } from "../../../models/host-user/update-hostUser";
import { httpContext } from "../../../lib/api/httpContext";

const UpdateHostUser = (props) => {
  const { hostUser } = props;
  const [gender, setGender] = useState(hostUser.user.gender === "Male" ? 1 : 0);
  const [userRole, setUserRole] = useState(hostUser.user.roleId);
  const [alert, setAlert] = useState(false);
  const [userStatus, setUserStatus] = useState(
    hostUser.user.userStatus === "Active" ? 1 : 0
  );

  const firstNameInputRef = useRef(hostUser.user.firstName);
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const birthdayInputRef = useRef();

  const formatDate = () => {
    const formattedDate = new Date(hostUser.user.birthday.slice(0, 10));
    let day = formattedDate.getDate();
    let month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear().toString();

    month = month.toString().length === 1 ? "0" + month : month;
    day = day.toString().length === 1 ? "0" + day : day;

    return year + "-" + month + "-" + day;
  };

  const formattedBirthday = formatDate();

  const selectGenderHandler = (event) => {
    setGender(event.target.value);
  };

  const selectUserStatus = (event) => {
    setUserStatus(event.target.value);
  };

  const selectUserRole = (event) => {
    setUserRole(event.target.value);
  };

  const submitUserUpdate = async () => {
    const userData = {
      firstName: firstNameInputRef.current.value,
      lastName: lastNameInputRef.current.value,
      email: emailInputRef.current.value,
      birthday: birthdayInputRef.current.value,
      gender: gender,
      userStatus: userStatus,
      roleId: userRole,
    };
    const user = new UpdateHostUserModel(hostUser.id, userData);

    const response = await httpContext(`hostUsers/${hostUser.id}`, "PUT", user);

    if (!response.ok) {
      alert("Failed to update the User");
    } else {
      setAlert(true);
    }
  };

  const deleteUserHandler = async () => {
    const response = await httpContext(`hostUsers/${hostUser.id}`, "DELETE");

    if (response.ok) {
      setAlert(true);
    } else {
      alert("failed to delete User");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        padding: "1rem",
        width: "100%",
        boxShadow: "0px 0px 8px 5px black",
        borderRadius: "10px",
      }}
    >
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={() => setAlert(false)}
      >
        <Alert
          onClose={() => setAlert(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          User-Update erfolgreich übermittelt
        </Alert>
      </Snackbar>

      <FormControl>
        <InputGroup>
          <TextField
            sx={{ width: "45%" }}
            inputRef={firstNameInputRef}
            variant="filled"
            type="text"
            label="Vorname"
            defaultValue={hostUser.user.firstName}
          />
          <TextField
            sx={{ width: "45%" }}
            inputRef={lastNameInputRef}
            variant="filled"
            tpe="text"
            label="Nachname"
            defaultValue={hostUser.user.lastName}
          />
        </InputGroup>
        <InputContainer>
          <TextField
            variant="filled"
            sx={{ width: "100%" }}
            inputRef={emailInputRef}
            type="email"
            label="Email"
            defaultValue={hostUser.user.email}
          />
        </InputContainer>
        <InputContainer>
          <TextField
            variant="filled"
            inputRef={birthdayInputRef}
            sx={{ width: "100%" }}
            type="date"
            label="Birthday"
            defaultValue={formattedBirthday}
          />
        </InputContainer>
      </FormControl>

      <FormControl sx={{ width: "15rem" }}>
        <InputContainer>
          <FormGroup>
            <TextField
              select
              label="Geschlecht"
              variant="filled"
              value={gender}
              onChange={selectGenderHandler}
            >
              <MenuItem value={0}>Frau</MenuItem>
              <MenuItem value={1}>Mann</MenuItem>
            </TextField>
          </FormGroup>
        </InputContainer>
        <InputContainer>
          <FormGroup>
            <TextField
              select
              label="Status"
              variant="filled"
              value={userStatus}
              onChange={selectUserStatus}
            >
              <MenuItem value={0}>Deaktiviert</MenuItem>
              <MenuItem value={1}>Aktiv</MenuItem>
            </TextField>
          </FormGroup>
        </InputContainer>
        <InputContainer>
          <FormGroup>
            <TextField
              select
              label="Rolle"
              variant="filled"
              value={userRole}
              onChange={selectUserRole}
            >
              <MenuItem value={2}>Host User</MenuItem>
              <MenuItem value={3}>Administrator</MenuItem>
              <MenuItem value={99}>GodMode</MenuItem>
            </TextField>
          </FormGroup>
        </InputContainer>
      </FormControl>
      <div style={{ display: "block", marginTop: "auto" }}>
        <InputContainer>
          <SubmitButtonFilled onClick={submitUserUpdate}>
            Update User
          </SubmitButtonFilled>
        </InputContainer>
        <InputContainer>
          <WarningButtonOutlined onClick={submitUserUpdate}>
            Reset Password
          </WarningButtonOutlined>
        </InputContainer>
        <InputContainer>
          <DangerButton
            onClick={deleteUserHandler}
            variant="outlined"
            color="error"
          >
            User löschen
          </DangerButton>
        </InputContainer>
      </div>
    </div>
  );
};

export default UpdateHostUser;
