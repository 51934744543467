import React from "react";
import AnalyticsWidget from "../../components/dashboard/AnalyticsWidget";
import { Breadcrumbs, Link } from "@mui/material";
import ChartWidget from "../../components/dashboard/ChartWidget";
import EventCardList from "../../components/events/EventCardList";
import { useEffect, useState } from "react";
import { httpContext } from "../../lib/api/httpContext";
import { useSelector } from "react-redux";
import EventConfiguration from "../../components/dashboard/EventConfiguration";
import { EventFormDashboard } from "./Dashboard-styles";
import { getGradient } from "../../components/analytics/charts/gradient-style";

const DashboardPage = () => {
  const randomIntGenerator = () => {
    return Math.floor(Math.random() * 255);
  };

  const [analyticsData, setAnalyticsData] = useState([]);
  const [activeEventsSales, setActiveEventsSales] = useState([]);
  const globalFilter = useSelector((state) => state.globalFilter);

  useEffect(() => {
    let hostQueryParam;
    globalFilter.hostId.length > 3
      ? (hostQueryParam = `HostId=${globalFilter.hostId}`)
      : (hostQueryParam = "");
    const requestParams = `?${hostQueryParam}&FromDate=${globalFilter.dateFrom}&UntilDate=${globalFilter.dateTo}&Interval=daily`;

    const getAnalytics = async () => {
      const response = await httpContext("analytics/host" + requestParams);
      const activeEventAnalyticsResponse = await httpContext(
        `analytics/event/filtered?hostId=${globalFilter.hostId}`
      );

      if (activeEventAnalyticsResponse.ok) {
        const activeEventAnalyticsData =
          await activeEventAnalyticsResponse.json();
        setActiveEventsSales(activeEventAnalyticsData);
      }

      if (response.ok) {
        const analyticsResponse = await response.json();
        const analyticsData = [];
        for (const key in analyticsResponse) {
          analyticsData.push(analyticsResponse[key]);
        }
        setAnalyticsData(analyticsData);
      } else {
        alert("failed to fetch analytics Data");
      }
    };
    getAnalytics();
  }, [
    analyticsData.length,
    globalFilter.dateFrom,
    globalFilter.dateTo,
    globalFilter.hostId,
    activeEventsSales.length,
  ]);

  const datasets = [];
  const labels = [];
  const avarageValues = [];

  if (analyticsData.length > 0) {
    const salesData = [];
    const revenueData = [];
    for (const key in analyticsData) {
      labels.push(
        new Date(analyticsData[key].date).toLocaleDateString("ch-CH", {
          month: "short",
          day: "2-digit",
        })
      );
      if (key > 0 && key <= analyticsData.length) {
        salesData.push(
          analyticsData[key].totalTicketsSold -
            analyticsData[key - 1].totalTicketsSold
        );
        avarageValues.push(
          salesData.reduce((a, b) => a + b, 0) / salesData.length
        );
      } else {
        salesData.push(analyticsData[key].totalTicketsSold);
        avarageValues.push(
          salesData.reduce((a, b) => a + b, 0) / salesData.length
        );
      }
      revenueData.push(analyticsData[key].revenue);
    }
    const activeLabels = [];
    const backgroundColor = [];
    const activeEventSales = [];

    for (const key in activeEventsSales) {
      activeEventSales.push(activeEventsSales[key].ticketSales);
      backgroundColor.push(`rgba(${randomIntGenerator()},${randomIntGenerator()},
        ${randomIntGenerator()}, 0.7)`);
      activeLabels.push(activeEventsSales[key].eventName);
    }

    const formattedData = {
      labels: activeLabels,
      datasets: [
        {
          backgroundColor: backgroundColor,
          data: activeEventSales,
          type: "doughnut",
          offset: 2,
          borderRadius: 2,
          borderWidth: 0,
          label: "Ticket Verkauf pro Event",
        },
      ],
    };

    datasets.push(
      {
        label: "Tickets Verkauft",
        data: salesData,
        type: "bar",
        tension: 0.2,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        order: 2,
      },
      {
        label: "Durchschnittliche Tickets verkauft",
        data: avarageValues,
        type: "line",
        borderColor: "#9c0cf8",
        tension: 0.2,
        backgroundColor: "#9c0cf8",
        order: 1,
      },
      {
        label: "Umsatz in CHF",
        data: revenueData,
      },
      {
        label: "Tickets pro Event",
        data: formattedData,
      }
    );
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
      </Breadcrumbs>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <AnalyticsWidget />
      </div>
      <div style={{ margin: "1rem", marginLeft: "auto" }}>
        <ChartWidget datasets={datasets} labels={labels} />
      </div>
      <div
        style={{
          marginTop: "2rem",
          justifyContent: "center",
          width: "auto",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <EventFormDashboard>
            <EventConfiguration />
          </EventFormDashboard>
          <div
            style={{
              overflowY: "hidden",
              height: "100%",
              width: "100%",
              margin: "1rem 0 0 1rem",
            }}
          >
            <EventCardList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
