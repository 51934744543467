import React from "react";

import { useEffect, useState } from "react";
import { httpContext } from "../../lib/api/httpContext";
import KpiWidget from "./widgets/KpiWidget";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";

const AnalyticsWidget = () => {
  const globalFilter = useSelector((state) => state.globalFilter);
  const [loading, setLoading] = useState(false);
  const [hostAnalytics, setHostAnalytics] = useState({});

  useEffect(() => {
    const params = `?HostId=${globalFilter.hostId}&CheckDate=${globalFilter.dateTo}`;

    const fetchKpiAnalytics = async () => {
      setLoading(true);
      const response = await httpContext(`analytics/host/kpi${params}`);

      if (response.ok) {
        const responseData = await response.json();
        setHostAnalytics(responseData);
      }
    };
    fetchKpiAnalytics();
    setLoading(false);
  }, [globalFilter.dateTo, globalFilter.hostId]);

  const alertMessage = (
    <div style={{ padding: "1rem" }}>
      <Alert severity="warning">
        <AlertTitle>No Data available!</AlertTitle>
        No Data available for the current set Filter. Please adjust the Date
        Range filter to retrieve Data!
      </Alert>
    </div>
  );

  if (Object.keys(hostAnalytics).length === 0) {
    return alertMessage;
  }

  const currencyFormatter = Intl.NumberFormat("ch-CH", {
    style: "currency",
    currency: "CHF",
  });

  const salesInPercent =
    (
      (hostAnalytics.KPI.totalTicketsSold / hostAnalytics.KPI.totalTickets) *
      100
    ).toFixed(2) + " %";

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <KpiWidget
        bgColor={"#a2bee3"}
        header="Aktive Events"
        value={hostAnalytics.KPI.activeEvents}
      />
      <KpiWidget
        bgColor={"#a2bee3"}
        header="Verfügbare Tickets"
        value={
          hostAnalytics.KPI.totalTickets - hostAnalytics.KPI.totalTicketsSold
        }
      />
      <KpiWidget
        bgColor={"#a2bee3"}
        header="Tickets Verkauft"
        value={hostAnalytics.KPI.totalTicketsSold}
        additionalText={salesInPercent}
      />
      <KpiWidget
        bgColor={"#a2bee3"}
        header="Umsatz in CHF"
        value={currencyFormatter.format(hostAnalytics.KPI.revenue)}
      />
    </div>
  );
};

export default AnalyticsWidget;
