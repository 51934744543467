import React from "react";
import { Badge, Card, CardContent, CardMedia, Typography } from "@mui/material";
import picture from "../../../assets/pictures/signIn_Front.jpeg";
import { useHistory } from "react-router-dom";
import { theme } from "../../../theme/theme";
import { useStyles } from "./EventCard-styles";

const EventCard = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { event } = props;

  const startDate = new Date(event.startDate).toLocaleDateString("ch-CH", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const onCardClickHandler = () => {
    history.push({
      pathname: `events/${event.eventId}`,
      stage: {
        eventName: event.eventName,
      },
    });
  };

  return (
    <div className={classes.wrapper}>
      <Badge
        color="secondary"
        badgeContent={event.ticketSales}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Card
          onClick={onCardClickHandler}
          sx={{
            boxShadow: (theme) => theme.shadowGroup.boxShadow,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <CardMedia component="img" image={picture} alt="event picture" />
          <CardContent
            sx={{
              backgroundColor: "rgb(162,190,227)",
              minHeight: "150px",
              overflow: "hidden",
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                [theme.breakpoints.down(1466)]: {
                  fontSize: "1rem",
                },
              }}
            >
              {event.eventName}
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {startDate}
            </Typography>
          </CardContent>
        </Card>
      </Badge>
    </div>
  );
};

export default EventCard;
