import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  InputContainer,
  SubmitButtonFilled,
} from "../ui/inputs/input-components";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { messageActions } from "../../store/message-slice";
import { CreateMessageModel } from "../../models/message/message-model";
import { httpContext } from "../../lib/api/httpContext";

const ContactForm = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const [messageCategory, setMessageCategory] = useState(0);
  const [showHeaderWarning, setShowHeaderWarning] = useState(false);
  const [showMessageBodyWarning, setShowMessageBodyWarning] = useState(false);
  const headerInputRef = useRef();
  const messageBodyInputRef = useRef();

  const headerMaxLength = 60;
  const messageBodyLength = 2500;

  const updateMessage = () => {
    if (headerInputRef.current.value.length >= headerMaxLength) {
      setShowHeaderWarning(true);
    } else {
      setShowHeaderWarning(false);
    }
    if (messageBodyInputRef.current.value.length > messageBodyLength) {
      setShowMessageBodyWarning(true);
    } else {
      setShowMessageBodyWarning(false);
    }
    dispatch(
      messageActions.createMessage({
        header: headerInputRef.current.value,
        messageBody: messageBodyInputRef.current.value,
      })
    );
  };

  const updateMessageCategory = (event) => {
    setMessageCategory(event.target.value);
  };

  const submitMessageHandler = async () => {
    const messageData = new CreateMessageModel(
      headerInputRef.current.value,
      messageBodyInputRef.current.value,
      messageCategory
    );

    const response = await httpContext("messages", "POST", messageData);

    if (!response.ok) {
      alert("Failed to send the message");
    }
    if (response.ok) {
      headerInputRef.current.value = "";
      messageBodyInputRef.current.value = "";
      setMessageCategory(0);
      window.location.reload();
    }
  };

  return (
    <FormControl sx={{ width: "25rem" }} onChange={updateMessage}>
      <InputContainer>
        <Select
          value={messageCategory}
          sx={{ width: "100%" }}
          onChange={updateMessageCategory}
          variant="filled"
        >
          <MenuItem value={0}>Anfrage</MenuItem>
          <MenuItem value={1}>Feedback</MenuItem>
          <MenuItem value={2}>Fehler melden</MenuItem>
          <MenuItem value={3}>Information</MenuItem>
          <MenuItem value={4}>Generell</MenuItem>
        </Select>
      </InputContainer>
      <InputContainer>
        <TextField
          disabled
          sx={{ width: "100%" }}
          variant="filled"
          label="Absender"
          defaultValue={authData.email}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          fullWidth
          required
          inputProps={{
            maxLength: headerMaxLength,
          }}
          variant="filled"
          label="Title"
          inputRef={headerInputRef}
        />
        {showHeaderWarning && (
          <FormHelperText>
            <Typography variant="h7" color="error">
              Maximal {headerMaxLength} Zeichen
            </Typography>
          </FormHelperText>
        )}
      </InputContainer>
      <InputContainer>
        <TextField
          fullWidth
          required
          inputRef={messageBodyInputRef}
          inputProps={{
            maxLength: messageBodyLength,
          }}
          variant="filled"
          label="Nachricht"
          multiline
          maxRows={5}
          minRows={5}
        />
        {showHeaderWarning && (
          <FormHelperText>
            <Typography variant="h7" color="error">
              Maximal {messageBodyLength} Zeichen
            </Typography>
          </FormHelperText>
        )}
      </InputContainer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SubmitButtonFilled onClick={submitMessageHandler}>
          Absenden
        </SubmitButtonFilled>
      </div>
    </FormControl>
  );
};

export default ContactForm;
