import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { CustomWidthTooltip200 } from "../../ui/view-elements/CustomToolTips";
import InfoIcon from "@mui/icons-material/Info";
import { getGradient } from "./gradient-style";
Chart.register(...registerables);

const BarChart = (props) => {
  const { labels, datasets, toolTipTitle } = props;

  const formattedDataSet = [];

  for (const key in datasets) {
    formattedDataSet.push({
      label: datasets[key].label,
      data: datasets[key].data,
      type: datasets[key].type,
      borderColor: datasets[key].borderColor,
      tension: datasets[key].tension,
      backgroundColor: datasets[key].backgroundColor,
      order: datasets[key].order,
    });
  }

  const stornoChart = {
    label: "Tickets Storniert",
    data: [-10, 0, -20, -7, -11, -19, -26, 0, -6, -25, -31, -10, -15],
    type: "bar",
    tension: 0.2,
    backgroundColor: "#fd3838",
    order: 2,
  };

  //formattedDataSet.push(stornoChart);

  const data = {
    labels: labels,
    datasets: formattedDataSet,
  };

  return (
    <div style={{ display: "flex" }}>
      <Bar data={data} />
      <CustomWidthTooltip200 title={toolTipTitle} placement="left-start" arrow>
        <InfoIcon
          sx={{
            color: "#3471f5",
            "&: hover": {
              cursor: "pointer",
            },
          }}
        />
      </CustomWidthTooltip200>
    </div>
  );
};

export default BarChart;
