import { GreenSwitch, InputContainer } from "../ui/inputs/input-components";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/user-slice";

const LoginCredentials = (props) => {
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState(2);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const passwordRepeatInputRef = useRef();
  const [accteptedAgb, setAcceptedAgb] = useState(false);

  const selectRoleHandler = (event) => {
    setUserRole(event.target.value);
  };

  const switchChangeHandle = () => {
    setAcceptedAgb((prevState) => !prevState);
  };

  const updateUserData = () => {
    dispatch(
      userActions.createUserCredentials({
        email: emailInputRef.current.value,
        password: passwordInputRef.current.value,
        acceptedAgb: userData.acceptedAgb,
        userRole: userRole,
      })
    );
  };

  return (
    <FormControl onChange={updateUserData}>
      <FormGroup>
        <InputContainer>
          <TextField
            variant="outlined"
            inputRef={emailInputRef}
            label="Email"
            type="email"
            sx={{ width: "100%" }}
          />
        </InputContainer>
        <InputContainer>
          <TextField
            variant="outlined"
            inputRef={passwordInputRef}
            label="Password"
            type="password"
            sx={{ width: "100%" }}
          />
        </InputContainer>
      </FormGroup>
      <InputContainer>
        <TextField
          variant="outlined"
          inputRef={passwordRepeatInputRef}
          label="Password wiederholen"
          type="password"
          sx={{ width: "100%" }}
        />
      </InputContainer>
      <InputContainer>
        <FormGroup>
          <Select value={userRole} onChange={selectRoleHandler}>
            <MenuItem value={2}>Standard Benutzer</MenuItem>
            <MenuItem value={3}>Administrator</MenuItem>
          </Select>
        </FormGroup>
      </InputContainer>
      <InputContainer>
        <FormGroup>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={accteptedAgb}
                onChange={switchChangeHandle}
              />
            }
            label="Mit den AGBs einverstanden"
          />
        </FormGroup>
      </InputContainer>
    </FormControl>
  );
};

export default LoginCredentials;
