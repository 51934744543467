import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventName: "",
  eventStatus: "Active",
  publishDate: "",
};

const EventFilterSlice = createSlice({
  name: "eventFilter",
  initialState: initialState,
  reducers: {
    setEventFilter(state, action) {
      state.eventName = action.payload.eventName;
      state.eventStatus = action.payload.eventStatus;
      state.publishDate = action.payload.publishDate;
    },
    setEventName(state, action) {
      state.eventName = action.payload.eventName;
    },
    setEventStatus(state, action) {
      state.eventStatus = action.payload.eventStatus;
    },
  },
});

export const EventFilterAction = EventFilterSlice.actions;

export default EventFilterSlice;
