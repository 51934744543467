import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpContext } from "../../lib/api/httpContext";
import { Breadcrumbs, Divider, Link } from "@mui/material";
import { useSelector } from "react-redux";
import EventDetailAnalytics from "../../components/events/eventDetail/analytics/EventDetailAnalytics";
import EventUpdateForm from "../../components/events/eventDetail/EventUpdateForm";
import EventTicketList from "../../components/events/eventDetail/eventTickets/EventTicketList";

const EventDetailPage = () => {
  const params = useParams();
  const globalFilter = useSelector((state) => state.globalFilter);
  const [event, setEvent] = useState({});
  const [eventAnalytics, setEventAnalytics] = useState();

  useEffect(() => {
    const getEvent = async () => {
      const response = await httpContext(`events/${params.eventId}`);

      const queryParam = `?EventId=${params.eventId}&FromDate=${globalFilter.dateFrom}&UntilDate=${globalFilter.dateTo}&interval=daily`;

      const eventAnalyticsResponse = await httpContext(
        `analytics/event${queryParam}`
      );

      if (eventAnalyticsResponse.ok) {
        const eventAnalyticsData = await eventAnalyticsResponse.json();
        setEventAnalytics(eventAnalyticsData);
      }
      if (!eventAnalyticsResponse.ok) {
        alert("failed to fetch analytics data");
      }

      if (response.ok) {
        const responseData = await response.json();
        setEvent(responseData);
      }
      if (!response.ok) {
        alert("Failed to load the Event");
      }
    };
    getEvent();
  }, [event.length, params.eventId]);

  if (!eventAnalytics) {
    return <p>Loading...</p>;
  }

  return (
    <div
      style={{
        padding: "1rem 0 0 2rem",
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/events">
          Events
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href={`/events/${event.eventId}`}
        >
          {event.eventName}
        </Link>
      </Breadcrumbs>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2rem 0 2rem 0",
        }}
      >
        <div
          style={{
            width: "20%",
            position: "sticky",
            height: "20vh",
            top: "7rem",
          }}
        >
          {eventAnalytics && <EventDetailAnalytics eventId={event.eventId} />}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 0.5rem 0 0.5rem",
            width: "60%",
            position: "sticky",
            height: "20vh",
            top: "8rem",
          }}
        >
          <EventUpdateForm event={event} />
        </div>
        <div
          style={{
            width: "100%",
            margin: "0 1rem 0 0.5rem",
          }}
        >
          <EventTicketList eventId={event.eventId} />
        </div>
      </div>
    </div>
  );
};

export default EventDetailPage;
