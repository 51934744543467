import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  InputContainer,
  SubmitButtonFilled,
} from "../../../ui/inputs/input-components";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { httpContext } from "../../../../lib/api/httpContext";

const CreateTicketForm = (props) => {
  const { eventId } = props;
  const priceInputRef = useRef("");
  const eventTicketData = useSelector((state) => state.eventTicket);
  const numberOfTicketsInputRef = useRef(undefined);
  const minAgeInputRef = useRef();
  const [ticketCategory, setTicketCategory] = useState(1);

  const ticketCategoryChangeHandler = (event) => {
    setTicketCategory(event.target.value);
  };

  const submitHandler = async () => {
    const ticketModel = {
      eventId: eventId,
      price: priceInputRef.current.value,
      ticketCategory: ticketCategory,
      numberOfTickets: numberOfTicketsInputRef.current.value,
      minAge: minAgeInputRef.current.value,
    };

    const response = await httpContext("eventTickets", "POST", ticketModel);
    if (!response.ok) {
      alert("Failed to create the Ticket");
    }
    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <FormControl
      sx={{
        width: "22rem",
        padding: "1rem",
        backgroundColor: "white",
        borderRadius: "8px",
      }}
    >
      <Typography
        color="secondary"
        align="center"
        sx={{ marginBottom: "1rem" }}
        variant="h6"
      >
        Neue Ticket-Kategorie erstellen
      </Typography>
      <InputContainer>
        <TextField
          variant="filled"
          label="Preis"
          type="number"
          fullWidth
          inputRef={priceInputRef}
          defaultValue={eventTicketData.price}
        />
      </InputContainer>
      <InputContainer>
        <Select
          value={ticketCategory}
          fullWidth
          onChange={ticketCategoryChangeHandler}
        >
          <MenuItem value={1}>Normal</MenuItem>
          <MenuItem value={2}>Special</MenuItem>
          <MenuItem value={3}>Lounge</MenuItem>
          <MenuItem value={4}>VIP</MenuItem>
        </Select>
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={minAgeInputRef}
          fullWidth
          label="Mindest Alter"
          type="number"
          defaultValue={eventTicketData.minAge}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={numberOfTicketsInputRef}
          fullWidth
          label="Anzahl Tickets"
          type="number"
          defaultValue={eventTicketData.numberOfAvailableTickets}
        />
      </InputContainer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SubmitButtonFilled onClick={submitHandler}>
          <Typography>Create Ticket</Typography>
        </SubmitButtonFilled>
      </div>
    </FormControl>
  );
};

export default CreateTicketForm;
