import { Step, Stepper, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const CustomStep = styled(Step)(({ theme }) => ({
  "& .Mui": {
    color: "red",
  },
}));

export const CustomStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepper-root": {
    color: "red",
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "red",
    "& .Mui-active": {
      color: "#e88300",
    },
    "& .Mui-completed": {
      color: theme.palette.secondary.main,
    },
  },
}));
