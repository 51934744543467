export class CreateEventModel {
  constructor(
    eventName,
    eventDescription,
    hostId = null,
    cityId,
    street,
    eventGenreId,
    entryPrice,
    startDate,
    eventStatuses,
    eventGenre,
    endDate,
    numberOfTickets,
    eventTickets
  ) {
    this.eventName = eventName;
    this.eventDescription = eventDescription;
    this.hostId = hostId;
    this.cityId = cityId;
    this.street = street;
    this.eventGenreId = eventGenreId;
    this.entryPrice = entryPrice;
    this.startDate = startDate;
    this.eventStatuses = eventStatuses;
    this.eventGenre = eventGenre;
    this.endDate = endDate;
    this.numberOfTickets = numberOfTickets;
    this.eventTickets = eventTickets;
  }
}
