import { styled, Switch } from "@mui/material";

export const InputContainer = styled("div")(({}) => ({
  width: "100%",
  marginBottom: "1rem",
}));

export const InputGroup = styled("div")(({}) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "1rem",
}));

export const SubmitButtonFilled = styled("button")(({}) => ({
  color: "white",
  width: "8rem",
  height: "2.5rem",
  backgroundColor: "#B777B2",
  borderRadius: "10px",
  border: "none",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const WarningButtonOutlined = styled("button")(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: "8rem",
  height: "2.5rem",
  borderWidth: "2px",
  borderColor: theme.palette.secondary.main,
  borderRadius: "10px",
  backgroundColor: "transparent",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const ButtonOutlined = styled("button")(({}) => ({
  color: "#B777B2",
  width: "8rem",
  height: "2.5rem",
  borderRadius: "8px",
  fontSize: "1.5rem",
  backgroundColor: "transparent",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const DangerButton = styled("button")(({}) => ({
  color: "#ff0000",
  width: "8rem",
  height: "2.5rem",
  borderWidth: "2px",
  borderColor: "#ff0000",
  borderRadius: "10px",
  backgroundColor: "transparent",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.success.main,
  },
  "& .MuiSwitch-track": {
    backgroundColor: "red",
  },
}));
