import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { httpContext } from "../../../lib/api/httpContext";

const HostItem = (props) => {
  const { host, hostList, setHostList } = props;

  const acceptHostHandler = async () => {
    const endpoint = `hosts/${host.id}?accepted=true`;
    const response = await httpContext(endpoint, "POST");

    if (!response.ok) {
      alert("failed");
    }
    const updatedHostList = hostList.filter((host) => host.id !== host.id);
    setHostList(updatedHostList);
  };

  const rejectHostHandler = async () => {
    const endpoint = `hosts/${host.id}?accepted=false`;
    const response = await httpContext(endpoint, "POST");

    if (!response.ok) {
      alert("failed");
    }
    const updatedHostList = hostList.filter((host) => host.id !== host.id);
    setHostList(updatedHostList);
  };

  return (
    <div style={{ marginRight: "5rem" }}>
      <Accordion
        sx={{
          margin: "0 0 0.75rem 0",
          backgroundColor: (theme) => theme.palette.primary.main,
          boxShadow: "2px 2px 8px 4px grey",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: "1.6rem" }}>{host.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <div>
              <p>Name:</p>
              <p>Email:</p>
              <p>PLZ & Ort:</p>
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <p>{host.name}</p>
              <p>{host.hostEmail}</p>
              <p>
                {host.zipCode} {host.cityName}
              </p>
            </div>
            <div style={{ marginLeft: "auto", marginRight: "30%" }}>
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={acceptHostHandler}
              >
                <CheckCircleIcon
                  sx={{
                    fontSize: "5rem",
                    "&:hover": {
                      color: "green",
                    },
                  }}
                />
              </IconButton>
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={rejectHostHandler}
              >
                <ThumbDownIcon
                  sx={{
                    fontSize: "5rem",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                />
              </IconButton>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HostItem;
