import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import HostAdminPage from "./HostAdminPage";
import HostUserList from "../../components/GodMode/HostUser/HostUserList";
import AdminAnalyticsPage from "./AdminAnalyticsPage";
import { useHistory, useParams } from "react-router-dom";
import MessagePage from "./MessagePage";

const AdminDashboardPage = () => {
  const params = useParams();
  const history = useHistory();
  const [value, setValue] = useState(0);

  useEffect(() => {
    switch (params.category) {
      case "analytics":
        setValue(0);
        break;
      case "requests":
        setValue(1);
        break;
      case "hosts":
        setValue(2);
        break;
      case "events":
        setValue(3);
        break;
      case "users":
        setValue(4);
        break;
      case "messages":
        setValue(5);
        break;
      default:
        setValue(0);
        break;
    }
  }, [params.category]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        history.push("/admin/analytics");
        break;
      case 1:
        history.push("/admin/requests");
        break;
      case 2:
        history.push("/admin/hosts");
        break;
      case 3:
        history.push("/admin/events");
        break;
      case 4:
        history.push("/admin/users");
        break;
      case 5:
        history.push("/admin/messages");
        break;
      default:
        history.push("/admin/analytics");
    }
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab
          value={0}
          label="Analytics"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
        <Tab
          value={1}
          label="Hosts Requests"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
        <Tab
          value={2}
          label="Hosts List"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
        <Tab
          value={3}
          label="Events"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
        <Tab
          value={4}
          label="User"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
        <Tab
          value={5}
          label="Nachrichten"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
      </Tabs>
      {value === 0 && (
        <AdminAnalyticsPage hostId={"08d9ed87-769e-40da-8e75-6758661d5460"} />
      )}
      {value === 1 && <HostAdminPage activeTab={value} />}
      {value === 2 && <HostAdminPage activeTab={value} />}
      {value === 4 && <HostUserList activeTab={value} />}
      {value === 5 && <MessagePage activeTab={value} />}
    </div>
  );
};

export default AdminDashboardPage;
