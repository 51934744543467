import React from "react";
import Login from "../../components/account/Login";

const LoginPage = () => {
  const iFrame = (
    <iframe
      width="1150"
      height="670"
      src="https://www.youtube.com/embed/TMJ_WOZIFMw?controls=0&amp&autoplay=1&mute=0;start=20"
      title="Itachi vs. Sasuke"
      frameBorder="0"
      allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope;"
      allowFullScreen
    />
  );

  const loginVideo = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "50%",
        backgroundColor: "black",
        height: "100%",
      }}
    >
      {iFrame}
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Login />
    </div>
  );
};

export default LoginPage;
