import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  email: "",
  phoneNumber: "",
  licenceType: 0,
  address: {
    street: "",
    cityId: "",
  },
  hostUsers: [],
};

const hostSlice = createSlice({
  name: "host",
  initialState: initialState,
  reducers: {
    createHost(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
      state.address.street = action.payload.street;
      state.address.cityId = action.payload.cityId;
      state.hostUsers = action.payload.hostUsers;
    },
    addHostUsers(state, action) {
      state.hostUsers = action.payload.hostUsers;
    },
    addCityToHost(state, action) {
      state.address.cityId = action.payload.cityId;
    },
    setLicenceType(state, action) {
      state.licenceType = action.payload.licenceType;
    },
    resetHostState(state) {
      state = initialState;
    },
  },
});

export const hostActions = hostSlice.actions;

export default hostSlice;
