import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateHostUser from "./UpdateHostUser";

const UserItem = (props) => {
  const { hostUser } = props;

  return (
    <Accordion
      sx={{
        margin: "0 0 0.75rem 0",
        backgroundColor: (theme) => theme.palette.primary.main,
        boxShadow: "2px 2px 2px 2px black",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {hostUser.user.firstName} {hostUser.user.lastName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <UpdateHostUser hostUser={hostUser} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default UserItem;
