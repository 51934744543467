export class UpdateEventTicketModel {
  constructor(
    eventTicketId,
    eventId,
    price,
    ticketCategory,
    numberOfTickets,
    minAge
  ) {
    this.eventTicketId = eventTicketId;
    this.eventId = eventId;
    this.price = price;
    this.ticketCategory = ticketCategory;
    this.numberOfTickets = numberOfTickets;
    this.minAge = minAge;
  }
}
