let width, height, gradient;

export const getGradient = (ctx, chartArea) => {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, `rgba(15, 117, 189, 0.7)`);
    gradient.addColorStop(0.6, `rgba(185, 130, 238, 0.7)`);
  }

  return gradient;
};
