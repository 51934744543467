import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import {
  InputContainer,
  InputGroup,
  SubmitButtonFilled,
} from "../../ui/inputs/input-components";
import { useRef, useState } from "react";
import { UpdateEventModel } from "../../../models/events/update-event";
import { httpContext } from "../../../lib/api/httpContext";
import { formatDateToIso } from "../../../lib/formatters/date-formatter";
import { theme } from "../../../theme/theme";

const EventUpdateForm = (props) => {
  const { event } = props;
  const [eventGenre, setEventGenre] = useState(0);

  const eventNameInputRef = useRef();
  const eventDescriptionInputRef = useRef();
  const entryPriceInputRef = useRef();
  const startDateInputRef = useRef();
  const endDateInputRef = useRef();

  const setGenreHandler = (event) => {
    setEventGenre(event.target.value);
  };

  const submitHandler = async () => {
    const eventModel = new UpdateEventModel(
      event.eventId,
      eventNameInputRef.current.value,
      eventDescriptionInputRef.current.value,
      eventGenre,
      entryPriceInputRef.current.value,
      startDateInputRef.current.value,
      endDateInputRef.current.value
    );

    const response = await httpContext(
      `events/${event.eventId}`,
      "PUT",
      eventModel
    );

    if (!response.ok) {
      alert("Failed to update Event");
    }
  };

  if (Object.keys(event).length === 0 && event.constructor === Object) {
    return <p>Loading Event...</p>;
  }

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={eventNameInputRef}
          defaultValue={event.eventName}
          label="Event Name"
          type="text"
          fullWidth
        />
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          label="Beschreibung"
          inputRef={eventDescriptionInputRef}
          defaultValue={event.eventDescription}
          type="text"
          multiline
          maxRows={10}
          minRows={5}
          fullWidth
        />
      </InputContainer>
      <InputContainer>
        <Select
          value={eventGenre}
          variant="filled"
          fullWidth
          onChange={setGenreHandler}
        >
          <MenuItem value={0}>Pop</MenuItem>
          <MenuItem value={1}>Rock</MenuItem>
          <MenuItem value={2}>RnB</MenuItem>
        </Select>
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          label="Eintritt"
          inputRef={entryPriceInputRef}
          type="number"
          fullWidth
          defaultValue={event.entryPrice}
        />
      </InputContainer>
      <InputGroup>
        <TextField
          sx={{ width: "48%" }}
          inputRef={startDateInputRef}
          defaultValue={formatDateToIso(event.startDate)}
          variant="filled"
          type="date"
        />
        <TextField
          sx={{ width: "48%" }}
          inputRef={endDateInputRef}
          defaultValue={formatDateToIso(event.endDate)}
          variant="filled"
          type="date"
        />
      </InputGroup>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SubmitButtonFilled onClick={submitHandler}>Update</SubmitButtonFilled>
      </div>
    </FormControl>
  );
};
export default EventUpdateForm;
