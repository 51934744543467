import { createSlice } from "@reduxjs/toolkit";

const localItem = JSON.parse(window.localStorage.getItem("Elang-Admin"));

const initialState = {
  hostId: localItem ? localItem.data.hostId : "",
  hostName: "",
  eventName: "",
  userName: "",
  dateFrom: "01/01/2022",
  dateTo: new Date().toLocaleDateString("en-US"),
};

const globalFilterSlice = createSlice({
  name: "globalFilter",
  initialState: initialState,
  reducers: {
    setDateRange(state, action) {
      state.dateFrom = action.payload.dateFrom;
      state.dateTo = action.payload.dateTo;
    },
    setEventFilter(state, action) {
      state.eventName = action.payload.eventName;
    },
    setHostName(state, action) {
      state.hostName = action.payload.hostName;
    },
    setHostId(state, action) {
      state.hostId = action.payload.hostId;
    },
    resetFilter(state) {
      state.hostId = localItem ? localItem.data.hostId : "";
      state.hostName = "";
      state.eventName = "";
      state.userName = "";
      state.dateFrom = "01/01/2022";
      state.dateTo = new Date().toLocaleDateString("en-US");
    },
  },
});

export const globalFilterActions = globalFilterSlice.actions;

export default globalFilterSlice;
