export const formatDate = (
  dateToConvert,
  convertFormat,
  dateOptions = null
) => {
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (dateOptions) {
    options = dateOptions;
  }

  return new Date(dateToConvert).toLocaleDateString(convertFormat, options);
};

export const formatDateToIso = (dateToConvert) => {
  if (!dateToConvert) {
    return "";
  }

  const formattedDate = new Date(dateToConvert).toISOString().split("T");
  return formattedDate[0];
};
