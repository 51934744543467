import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  listLayout: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    position: "stick",
    "@media (max-width:1500px)": {
      maxWidth: "100%",
      justifyContent: "flex-start",
    },
  },
  video: {
    margin: "5rem 4rem 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1500)]: {
      marginTop: "7rem",
      marginLeft: "6rem",
      maxWidth: "560px",
      maxHeight: "315px",
    },
  },
}));
