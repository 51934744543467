import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { InputContainer } from "../ui/inputs/input-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { EventFilterAction } from "../../store/event-filter-slice";
import { globalFilterActions } from "../../store/global-filter-slice";

const EventsFilter = () => {
  const dispatch = useDispatch();
  const globalFilter = useSelector((state) => state.globalFilter);
  const eventFilter = useSelector((state) => state.eventFilter);
  const eventNameInputRef = useRef("");
  const [eventStatus, setEventStatus] = useState("Active");

  useEffect(() => {
    dispatch(
      EventFilterAction.setEventName({
        eventName: globalFilter.eventName,
      })
    );
  }, [globalFilter.eventName, eventFilter.eventName, eventFilter.eventStatus]);

  const updateFilterForm = (event) => {
    dispatch(
      globalFilterActions.setEventFilter({
        eventName: event.target.value,
      })
    );
  };

  const updateEventStatus = (event) => {
    setEventStatus(event.target.value);
    dispatch(
      EventFilterAction.setEventStatus({
        eventStatus: event.target.value,
      })
    );
  };

  return (
    <FormControl
      onChange={updateFilterForm}
      sx={{
        padding: "1rem",
        borderRadius: "8px",
        width: "80%",
        boxShadow: (theme) => theme.shadowGroup.boxShadow,
      }}
    >
      <InputContainer>
        <TextField
          type="text"
          variant="filled"
          sx={{ width: "100%" }}
          inputRef={eventNameInputRef}
          label="Name des Events"
          defaultValue={globalFilter.eventName}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          type="date"
          variant="filled"
          sx={{ width: "100%" }}
          inputRef={eventNameInputRef}
        />
        <FormHelperText>Start des Events</FormHelperText>
      </InputContainer>
      <InputContainer>
        <TextField
          type="date"
          variant="filled"
          sx={{ width: "100%" }}
          inputRef={eventNameInputRef}
        />
        <FormHelperText>Ende des Events</FormHelperText>
      </InputContainer>
      <InputContainer>
        <Select
          value={eventStatus}
          sx={{ width: "100%" }}
          variant="filled"
          onChange={updateEventStatus}
        >
          <MenuItem value={"Active"}>Aktiv Events</MenuItem>
          <MenuItem value={"Expired"}>Vergangene Events</MenuItem>
          <MenuItem value={"Ongoing"}>Laufende Events</MenuItem>
          <MenuItem value={"AllEvents"}>Alle Events</MenuItem>
        </Select>
        <FormHelperText>Status des Events</FormHelperText>
      </InputContainer>
    </FormControl>
  );
};

export default EventsFilter;
