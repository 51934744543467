import React, { useEffect, useState } from "react";
import { httpContext } from "../../lib/api/httpContext";
import UserList from "../../components/user/dataDisplay/UserList";
import { useSelector } from "react-redux";

const UserPage = () => {
  const globalFilter = useSelector((state) => state.globalFilter);
  const [hostUserList, setHostUserList] = useState([]);

  useEffect(() => {
    const getAllHostUsers = async () => {
      const hostIdParam = `?hostId=${globalFilter.hostId}`;
      const response = await httpContext("hostUsers" + hostIdParam);

      if (response.ok) {
        const userData = await response.json();
        setHostUserList(userData);
      } else {
        alert("Failed to fetch User List");
      }
    };
    getAllHostUsers();
  }, [hostUserList.length, globalFilter.hostId]);

  return (
    <>
      <UserList hostUsers={hostUserList} />
    </>
  );
};

export default UserPage;
