import {
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { InputContainer, InputGroup } from "../ui/inputs/input-components";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/user-slice";

const UserPersonalData = () => {
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const birthdayInputRef = useRef();

  const updateUserData = () => {
    dispatch(
      userActions.createUserData({
        firstName: firstNameInputRef.current.value,
        lastName: lastNameInputRef.current.value,
        gender: userData.gender,
        birthday: birthdayInputRef.current.value,
      })
    );
  };

  const selectGenderHandler = (event) => {
    dispatch(userActions.createUserData({ gender: event.target.value }));
  };

  return (
    <FormControl onChange={updateUserData}>
      <InputGroup>
        <TextField
          variant="outlined"
          inputRef={firstNameInputRef}
          defaultValue={userData.firstName}
          label="Vorname"
          title="Hello"
          type="text"
          sx={{ width: "48%" }}
        />
        <TextField
          variant="outlined"
          inputRef={lastNameInputRef}
          defaultValue={userData.lastName}
          label="Nachname"
          title="Hello"
          type="text"
          sx={{ width: "48%" }}
        />
      </InputGroup>
      <InputContainer>
        <FormGroup>
          <Select value={userData.gender} onChange={selectGenderHandler}>
            <MenuItem value={0}>Frau</MenuItem>
            <MenuItem value={1}>Mann</MenuItem>
          </Select>
        </FormGroup>
      </InputContainer>
      <InputContainer>
        <TextField
          variant="outlined"
          inputRef={birthdayInputRef}
          defaultValue={userData.birthday}
          helperText="Geburtsdatum"
          type="date"
          sx={{ width: "100%" }}
        />
      </InputContainer>
    </FormControl>
  );
};

export default UserPersonalData;
