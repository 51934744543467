import MessageList from "./MessageList";
import { useSelector } from "react-redux";

const MessageInbox = () => {
  const authData = useSelector((state) => state.auth);
  const query = `?UserId=${authData.id}`;

  return <MessageList endpoint={`messages${query}`} />;
};

export default MessageInbox;
