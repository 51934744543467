import React from "react";
import { Search, SearchIconWrapper, StyledInputBase } from "./StyledComponents";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";

const SearchInputField = (props) => {
  const { placeHolder, onChange, onSearch, defaultValue } = props;

  return (
    <Search
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        value={defaultValue}
        placeholder={placeHolder}
        onChange={onChange}
        inputProps={{ "aria-label": "search" }}
      />
      <Button
        sx={{ marginRight: "0.5rem" }}
        color="secondary"
        onClick={onSearch}
        disableRipple
        disableFocusRipple
        disableTouchRipple
      >
        search
      </Button>
    </Search>
  );
};

export default SearchInputField;
