import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { InputContainer } from "../../ui/inputs/input-components";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticketActions } from "../../../store/ticket-slice";

const EventTicketInformation = () => {
  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.event);
  const entryFeeInputRef = useRef("");
  const numberOfTicketsInputRef = useRef("");
  const [ticketCategory, setTicketCategory] = useState(1);

  const ticketCategorySelector = (event) => {
    setTicketCategory(event.target.value);
  };

  const ticketChangeHandler = () => {
    dispatch(
      ticketActions.createEventTicket({
        price: entryFeeInputRef.current.value,
        ticketCategory: ticketCategory,
        numberOfTickets: numberOfTicketsInputRef.current.value,
        numberOfAvailableTickets: numberOfTicketsInputRef.current.value,
      })
    );
  };

  return (
    <FormControl onChange={ticketChangeHandler}>
      <InputContainer>
        <TextField
          variant="filled"
          label="Eintritt in CHF"
          inputRef={entryFeeInputRef}
          defaultValue={eventData.entryPrice}
          type="number"
          sx={{ width: "100%" }}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          label="Anzahl Tickets"
          inputRef={numberOfTicketsInputRef}
          defaultValue={eventData.numberOfTickets}
          type="number"
          sx={{ width: "100%" }}
        />
      </InputContainer>
      <InputContainer>
        <Select
          variant="filled"
          value={ticketCategory}
          sx={{ width: "100%" }}
          onChange={ticketCategorySelector}
        >
          <MenuItem value={1}>Standard</MenuItem>
          <MenuItem value={2}>VIP</MenuItem>
        </Select>
        <FormHelperText>Wähle die Ticket-Kategorie aus</FormHelperText>
      </InputContainer>
      <InputContainer>
        <input type="file" multiple />
        <FormHelperText>Wähle dein Cover aus</FormHelperText>
      </InputContainer>
    </FormControl>
  );
};

export default EventTicketInformation;
