import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  InputContainer,
  SubmitButtonFilled,
} from "../../../ui/inputs/input-components";
import { useRef, useState } from "react";
import { UpdateEventTicketModel } from "../../../../models/events/update-eventTicket";
import { httpContext } from "../../../../lib/api/httpContext";
import { theme } from "../../../../theme/theme";

const EventTicketUpdate = (props) => {
  const { ticket } = props;
  const [ticketCategory, setTicketCategory] = useState(1);

  const priceInputRef = useRef();
  const numberOfTickets = useRef();
  const minAgeInputRef = useRef();

  const updateTicketCategory = (event) => {
    setTicketCategory(event.target.value);
  };

  const submitHandler = async () => {
    const ticketModel = new UpdateEventTicketModel(
      ticket.eventTicketId,
      ticket.eventId,
      parseFloat(priceInputRef.current.value),
      ticketCategory,
      parseInt(numberOfTickets.current.value),
      minAgeInputRef.current.value
    );

    const response = await httpContext(
      `eventTickets/${ticket.eventTicketId}`,
      "PUT",
      ticketModel
    );

    if (response.ok) {
      window.location.reload();
    }
    if (!response.ok) {
      alert("Failed to update existing ticket");
    }
  };

  return (
    <FormControl
      sx={{
        width: "100%",
        maxWidth: "16rem",
      }}
    >
      <Typography
        variant="h6"
        color="secondary"
        align="center"
        sx={{ marginBottom: "1rem" }}
      >
        Update Ticket
      </Typography>
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={priceInputRef}
          fullWidth
          label="Preis"
          defaultValue={ticket.price}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={numberOfTickets}
          fullWidth
          label="Anzahl Tickets"
          defaultValue={ticket.numberOfTickets}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          type="number"
          inputRef={minAgeInputRef}
          fullWidth
          label="Mindest Alter"
          defaultValue={ticket.minAge}
        />
      </InputContainer>
      <InputContainer>
        <Select
          value={ticketCategory}
          variant="filled"
          fullWidth
          onChange={updateTicketCategory}
        >
          <MenuItem value={1}>Normal</MenuItem>
          <MenuItem value={2}>Special</MenuItem>
          <MenuItem value={3}>Lounge</MenuItem>
          <MenuItem value={4}>VIP</MenuItem>
        </Select>
      </InputContainer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SubmitButtonFilled onClick={submitHandler}>
          Update Ticket
        </SubmitButtonFilled>
      </div>
    </FormControl>
  );
};

export default EventTicketUpdate;
