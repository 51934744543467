import React from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import {
  ButtonOutlined,
  InputContainer,
  SubmitButtonFilled,
} from "../ui/inputs/input-components";
import { useRef } from "react";
import { LoginModel } from "../../models/account/login-model";
import { httpContext } from "../../lib/api/httpContext";
import logo from "../../assets/logo/Logo.png";
import { saveToLocalStorage } from "../../lib/storage/localStorage";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useHistory } from "react-router-dom";

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const loginHandler = async () => {
    const loginModel = new LoginModel(
      emailInputRef.current.value,
      passwordInputRef.current.value
    );

    const loginResponse = await httpContext(
      "account/authenticate",
      "POST",
      loginModel
    );

    if (loginResponse.ok) {
      const loginData = await loginResponse.json();
      saveToLocalStorage("Elang-Admin", loginData);
      dispatch(authActions.login());
    } else {
      alert("Login failed");
    }
  };

  const redirectToRegistration = () => {
    history.push("/registration");
  };

  return (
    <FormControl
      sx={{
        backgroundColor: "white",
        marginTop: "3rem",
        borderRadius: "10px",
        width: "30rem",
        height: "30rem",
        padding: "1rem",
        boxShadow: "2px 5px 15px 2px black",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={logo} alt="Logo" />
      </div>
      <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>
        Login to your account
      </h2>
      <InputContainer>
        <TextField
          sx={{ width: "100%" }}
          inputRef={emailInputRef}
          variant="outlined"
          type="email"
          label="Email"
        />
      </InputContainer>
      <InputContainer>
        <TextField
          sx={{ width: "100%" }}
          inputRef={passwordInputRef}
          variant="outlined"
          type="password"
          label="Password"
        />
      </InputContainer>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <ButtonOutlined onClick={redirectToRegistration}>
          <Typography variant="h5">Register</Typography>
        </ButtonOutlined>
        <SubmitButtonFilled onClick={loginHandler}>
          <Typography variant="h5">Login</Typography>
        </SubmitButtonFilled>
      </div>
    </FormControl>
  );
};

export default Login;
