import BarChart from "../analytics/charts/BarChart";
import React from "react";
import LineChart from "../analytics/charts/LineChart";
import DoughnutChart from "../analytics/charts/DoughnutChart";
import {
  averageAndTotalTicketSales,
  doughnutEventChart,
  revenueChartDescription,
} from "../analytics/charts/chart-explenations";
import { getGradient } from "../analytics/charts/gradient-style";

const ChartWidget = (props) => {
  const { datasets, labels, vertical } = props;

  if (datasets.length === 0) {
    return <p>No data...</p>;
  }

  return (
    <div
      style={{
        display: vertical ? "block" : "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginBottom: "1rem",
        flexWrap: "wrap",
      }}
    >
      <div style={{ width: "40%" }}>
        <BarChart
          datasets={datasets.slice(0, 2)}
          labels={labels}
          toolTipTitle={averageAndTotalTicketSales}
        />
      </div>
      <div style={{ width: "40%" }}>
        <LineChart
          dataset={datasets[2]}
          labels={labels}
          toolTipTitle={revenueChartDescription}
        />
      </div>
      <div style={{ width: "20%" }}>
        <DoughnutChart
          dataset={datasets[3]}
          toolTipTitle={doughnutEventChart}
        />
      </div>
    </div>
  );
};

export default ChartWidget;
