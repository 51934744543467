import React, { useEffect, useState } from "react";
import PageRoutes from "./routes/PageRoutes";
import NavigationBar from "./components/ui/navigation/NavigationBar";
import SideDrawer from "./components/ui/drawer/SideDrawer";
import { useDispatch, useSelector } from "react-redux";
import UnauthorizedRoutes from "./routes/UnauthorizedRoutes";
import { globalFilterActions } from "./store/global-filter-slice";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const authData = useSelector((state) => state.auth);
  const uiData = useSelector((state) => state.ui);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      globalFilterActions.setHostId({
        hostId: authData.hostId,
      })
    );

    if (uiData.modalOpen || uiData.eventModal) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [uiData.modalOpen, uiData.eventModal]);

  return (
    <>
      {isLoggedIn && (
        <>
          <NavigationBar />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "15%" }}>
              <SideDrawer />
            </div>
            <div
              style={{
                maxWidth: "83%",
                width: "100%",
                marginTop: "4rem",
                marginLeft: "auto",
                filter: modalOpen ? "blur(7px)" : "",
              }}
            >
              <PageRoutes />
            </div>
          </div>
        </>
      )}
      {!isLoggedIn && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UnauthorizedRoutes />
        </div>
      )}
    </>
  );
}

export default App;
