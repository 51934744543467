import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const HostModalList = (props) => {
  const { hostList, onClick } = props;

  if (hostList.length === 0) {
    return <h1>No Data</h1>;
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "800px",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", textDecoration: "underline" }}>
              Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", textDecoration: "underline" }}>
              PLZ
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", textDecoration: "underline" }}>
              Stadt
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", textDecoration: "underline" }}>
              Email
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hostList.map((host, index) => (
            <TableRow
              key={index}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
              onClick={() => onClick(host.id, host.name)}
            >
              <TableCell>{host.name}</TableCell>
              <TableCell>{host.zipCode}</TableCell>
              <TableCell>{host.cityName}</TableCell>
              <TableCell>{host.hostEmail}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default HostModalList;
