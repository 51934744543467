import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  layout: {
    padding: "1rem 0 0 2rem",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  filter: {
    marginTop: "2rem",
    width: "22rem",
    [theme.breakpoints.up(1600)]: {
      position: "sticky",
      top: "8rem",
      height: "80vh",
    },
    [theme.breakpoints.down(1500)]: {
      position: "sticky",
      height: "80vh",
      top: "8rem",
    },
  },
}));
