import React from "react";
import { useEffect, useState } from "react";
import EventCard from "./elements/EventCard";
import { httpContext } from "../../lib/api/httpContext";
import { useStyles } from "./EventCardList-styles";
import { useSelector } from "react-redux";

const EventCardList = () => {
  const globalFilter = useSelector((state) => state.globalFilter);
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  const iFrame = (
    <div className={classes.video}>
      <iframe
        width="1000"
        height="450"
        src="https://www.youtube.com/embed/vfnBspBgxGY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );

  useEffect(() => {
    const queries = `?hostId=${globalFilter.hostId}&eventStatuses[]=Active&eventStatuses[]=Ongoing`;

    const getAllEvents = async () => {
      const response = await httpContext(`hosts/events${queries}`);
      const responseData = await response.json();

      if (responseData.length === 0) {
        setShowVideo(true);
      } else {
        setShowVideo(false);
      }

      if (globalFilter.eventName.length > 2) {
        const filteredEvents = events.filter((item) =>
          item.eventName
            .toLowerCase()
            .includes(globalFilter.eventName.toLowerCase())
        );
        setEvents(filteredEvents);
      } else {
        setEvents(responseData);
      }
    };
    getAllEvents();
  }, [events.length, globalFilter.eventName, globalFilter.hostId]);

  if (showVideo) {
    return iFrame;
  }

  return (
    <div className={classes.listLayout}>
      {events.map((event, index) => (
        <EventCard key={index} event={event} />
      ))}
    </div>
  );
};

export default EventCardList;
