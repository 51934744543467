import { useEffect, useState } from "react";
import { httpContext } from "../../../lib/api/httpContext";
import { DataGrid } from "@mui/x-data-grid";

const AllHostList = (props) => {
  const { hostDataList } = props;
  const [hostList, setHostList] = useState([]);

  useEffect(() => {
    if (hostDataList) {
      setHostList(hostDataList);
      return;
    }

    const getAllHosts = async () => {
      const endpoint =
        "hosts?hostStatuses[]=active&hostStatuses[]=approvalrequest&hostStatuses[]=notApproved&hostStatuses[]=Inactive&hostStatuses[]=Deleted";
      const response = await httpContext(endpoint);

      if (response.ok) {
        const hostListData = await response.json();
        setHostList(hostListData);
      }
      if (!response.ok) {
        alert("Failed to fetch Hosts");
      }
    };
    getAllHosts();
  }, [hostList.length]);

  if (hostList.length === 0) {
    return <h3>No Data...</h3>;
  }

  const columns = [
    { field: "name", headerName: "Name", width: 200, editable: true },
    { field: "hostEmail", headerName: "Email", width: 250, editable: true },
    { field: "zipCode", headerName: "PLZ", width: 130, editable: true },
    { field: "cityName", headerName: "Stadt", width: 180, editable: true },
    { field: "hostStatus", headerName: "Status", width: 180, editable: true },
    {
      field: "createdDate",
      headerName: "Erstellt",
      width: 150,
      editable: true,
    },
    { field: "phoneNumber", headerName: "Telefon", width: 180, editable: true },
    { field: "licenceType", headerName: "Lizenz", width: 180, editable: true },
  ];

  const rows = [];

  if (hostList.length > 0) {
    for (const key in hostList) {
      rows.push({
        id: hostList[key].id,
        name: hostList[key].name,
        hostEmail: hostList[key].hostEmail,
        zipCode: hostList[key].zipCode,
        cityName: hostList[key].cityName,
        hostStatus: hostList[key].hostStatus,
        phoneNumber: hostList[key].phoneNumber,
        licenceType: hostList[key].licenceType,
        createdDate: new Date(hostList[key].createdDate).toLocaleDateString(
          "ch-CH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ),
      });
    }
  }

  return (
    <div style={{ display: "flex", height: "700px" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        pageSize={20}
        rowsPerPageOptions={[20]}
      />
    </div>
  );
};

export default AllHostList;
