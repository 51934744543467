export const saveToLocalStorage = (itemName, data) => {
  const dataToStore = {
    data: data,
    isLoggedIn: true,
  };
  window.localStorage.setItem(itemName, JSON.stringify(dataToStore));
};

export const removeFromLocalStorage = (itemName) => {
  window.localStorage.removeItem(itemName);
};

export const getFromLocalStorage = (itemName) => {
  const data = window.localStorage(itemName);
  return JSON.parse(data);
};
