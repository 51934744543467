//const BASE_URL = "https://localhost:5001/api/";
const BASE_URL = "https://euvent.io/api/";

export const httpContext = async (apiEndpoint, method, body) => {
  const url = BASE_URL + apiEndpoint;

  switch (method) {
    case "GET":
      return await getRequest(url);
    case "POST":
      return await postRequest(url, body);
    case "PUT":
      return await putRequest(url, body);
    case "DELETE":
      return await deleteRequest(url);
    default:
      return await getRequest(url);
  }
};

const deleteRequest = async (url) => {
  const token = getTokenFromLocalStorage();
  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "same-origin",
  });
};

const putRequest = async (url, body) => {
  const token = getTokenFromLocalStorage();
  return await fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "same-origin",
  });
};

const getRequest = async (url) => {
  const token = getTokenFromLocalStorage();
  return await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "same-origin",
  });
};

const postRequest = async (url, body) => {
  const token = getTokenFromLocalStorage();
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

const getTokenFromLocalStorage = () => {
  const token = window.localStorage.getItem("Elang-Admin");
  if (token) {
    const parsedToken = JSON.parse(token);
    return parsedToken.data.token;
  }
  return "";
};
