import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  firstName: "",
  lastName: "",
  gender: 0,
  userRole: 0,
  birthday: "",
  email: "",
  password: "",
  acceptedAgb: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    createUserData(state, action) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.gender = action.payload.gender;
      state.birthday = action.payload.birthday;
    },
    createUserCredentials(state, action) {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.acceptedAgb = action.payload.acceptedAgb;
      state.userRole = action.payload.userRole;
    },
    clearUserDataState(state) {
      state.firstName = "";
      state.lastName = "";
      state.gender = 0;
      state.userRole = 0;
      state.birthday = "";
      state.email = "";
      state.password = "";
      state.acceptedAgb = false;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
