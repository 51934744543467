import { createSlice } from "@reduxjs/toolkit";

const TicketSlice = createSlice({
  name: "ticket",
  initialState: {
    price: 0,
    ticketCategory: 0,
    numberOfTickets: 0,
    numberOfAvailableTickets: 0,
  },
  reducers: {
    createEventTicket(state, action) {
      state.price = action.payload.price;
      state.ticketCategory = action.payload.ticketCategory;
      state.numberOfTickets = action.payload.numberOfTickets;
      state.numberOfAvailableTickets = action.payload.numberOfAvailableTickets;
    },
    resetEventTicketForm(state) {
      state.price = undefined;
      state.ticketCategory = 0;
      state.numberOfTickets = 0;
      state.numberOfAvailableTickets = 0;
    },
  },
});

export const ticketActions = TicketSlice.actions;

export default TicketSlice;
