import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import MessageList from "../../components/contact/MessageList";
import { httpContext } from "../../lib/api/httpContext";
import { useSelector } from "react-redux";

const MessagePage = () => {
  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={onTabChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab value={0} label="Eingang" disableRipple disableFocusRipple />
        <Tab value={1} label="Archiv" disableRipple disableFocusRipple />
      </Tabs>
      {tabValue === 0 && <MessageList endpoint="messages/all" />}
    </div>
  );
};

export default MessagePage;
