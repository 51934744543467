import { InputContainer } from "../../ui/inputs/input-components";
import {
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/user-slice";

const HostUserRegistration = () => {
  const dispatch = useDispatch();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const birthdayInputRef = useRef();

  const [gender, setGender] = useState(0);

  const selectGenderHandler = (event) => {
    setGender(event.target.value);
  };

  const userDataUpdate = () => {
    dispatch(
      userActions.createUserData({
        firstName: firstNameInputRef.current.value,
        lastName: lastNameInputRef.current.value,
        gender: gender,
        birthday: birthdayInputRef.current.value,
      })
    );
  };

  return (
    <FormControl onChange={userDataUpdate}>
      <FormGroup>
        <h3>User Basis Informationen</h3>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={firstNameInputRef}
            variant="filled"
            type="text"
            label="Vorname"
          />
        </InputContainer>
        <InputContainer>
          <TextField
            sx={{ width: "100%" }}
            inputRef={lastNameInputRef}
            variant="filled"
            type="text"
            label="Nachname"
          />
        </InputContainer>
        <InputContainer>
          <FormGroup>
            <Select
              variant="filled"
              value={gender}
              onChange={selectGenderHandler}
            >
              <MenuItem value={0}>Frau</MenuItem>
              <MenuItem value={1}>Mann</MenuItem>
            </Select>
          </FormGroup>
        </InputContainer>
        <InputContainer>
          <TextField
            variant="filled"
            inputRef={birthdayInputRef}
            helperText="Geburtsdatum"
            type="date"
            sx={{ width: "100%" }}
          />
        </InputContainer>
      </FormGroup>
    </FormControl>
  );
};

export default HostUserRegistration;
