import { PolarArea } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import CustomPieChart from "../../../analytics/charts/CustomPieChart";
import { httpContext } from "../../../../lib/api/httpContext";
import { CircularProgress } from "@mui/material";

const EventDetailAnalytics = (props) => {
  const { eventId, width } = props;
  const [eventGenderAnalytics, setEventGenderAnalytics] = useState();
  const [polarAreaAnalytics, setPolarAreaAnalytics] = useState();

  useEffect(() => {
    if (!eventId) {
      return <p>Loading Event Data..</p>;
    }
    const query = `?EventId=${eventId}`;
    const getGenderAnalytics = async () => {
      const response = await httpContext(`analytics/events/gender${query}`);
      const ageCluster = await httpContext(
        `analytics/event/ageCluster${query}`
      );

      if (ageCluster.ok) {
        const ageClusterData = await ageCluster.json();
        setPolarAreaAnalytics({
          labels: [
            "Unter 21 Jahre",
            "21 bis 23 Jahre",
            "24 bis 27 Jahre",
            "28 bis 32 Jahre",
            "Über 32 Jahre",
          ],
          datasets: [
            {
              data: [
                ageClusterData.under21Years,
                ageClusterData.between21And23Years,
                ageClusterData.between24And27Years,
                ageClusterData.between28And32Years,
                ageClusterData.over32Years,
              ],
              backgroundColor: [
                `rgb(54, 162, 235, 0.7)`,
                `rgba(255, 99, 132, 0.7)`,
                `rgba(54, 235, 187, 0.7)`,
                `rgba(144, 222, 113, 0.7)`,
                `rgba(229, 202, 91, 0.7)`,
              ],
              borderWidth: 0,
              offset: 2,
            },
          ],
        });
      }

      if (!ageCluster.ok) {
        alert("Failed to laod Age Cluster data");
      }

      if (response.ok) {
        const responseData = await response.json();
        const formattedData = {
          labels: ["Male", "Female"],
          datasets: [
            {
              label: "Verteilung auf Geschlechter",
              data: [
                responseData.maleInPercent.toFixed(2),
                responseData.femaleInPercent.toFixed(2),
              ],
              offset: 2,
              borderWidth: 0,
              backgroundColor: [
                `rgb(54, 162, 235, 0.7)`,
                `rgba(255, 99, 132, 0.7)`,
              ],
            },
          ],
        };

        setEventGenderAnalytics(formattedData);
      }
    };
    getGenderAnalytics();
  }, [eventId]);

  return (
    <div style={{ width: width ? width : "" }}>
      {polarAreaAnalytics ? (
        <PolarArea
          data={polarAreaAnalytics}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: "Alters Verteilung",
                font: {
                  size: 18,
                },
              },
            },
          }}
          type="polarArea"
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "4rem",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      {eventGenderAnalytics ? (
        <CustomPieChart
          data={eventGenderAnalytics}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: "Geschlechter Verteilung",
                font: {
                  size: 18,
                },
              },
            },
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "4rem",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};
export default EventDetailAnalytics;
