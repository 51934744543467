import { styled, Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material";

export const CustomWidthTooltip200 = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});
