import { styled } from "@mui/material";

export const EventFormDashboard = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.up(1600)]: {
    position: "sticky",
    height: "90vh",
    top: "7rem",
  },
  [theme.breakpoints.down(1500)]: {
    position: "sticky",
    height: "90vh",
    top: "-5rem",
  },
}));
