import { InputContainer, InputGroup } from "../../ui/inputs/input-components";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { httpContext } from "../../../lib/api/httpContext";
import { eventActions } from "../../../store/event-slice";

const EventBasicInformation = () => {
  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.event);
  const [query, setQuery] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("");
  const [eventGenreId, setEventGenreId] = useState(1);

  const eventNameInputRef = useRef("");
  const eventDescriptionInputRef = useRef("");
  const eventStartDateInputRef = useRef("");
  const eventEndDateInputRef = useRef("");
  const streetInputRef = useRef("");

  const cityParamHandler = (event) => {
    setQuery(event.target.value);
  };

  const eventTypeSelector = (event) => {
    setEventGenreId(event.target.value);
  };

  const citySelectHandler = (event) => {
    setSelectedCityId(event.target.value);
    dispatch(
      eventActions.addCityToEvent({
        cityId: event.target.value,
      })
    );
  };

  useEffect(() => {
    const citySearchHandler = async () => {
      if (query.length < 3) {
        return;
      }
      const queryParam = "cityName=" + query;
      const response = await httpContext("cities/filter?" + queryParam);
      if (response.ok) {
        const cityData = await response.json();
        setFilteredCities(cityData);
      }
      if (!response.ok) {
        alert("Failed to fetch cities");
      }
    };
    citySearchHandler();
  }, [query, filteredCities.length]);

  const updateEventState = () => {
    dispatch(
      eventActions.createBasicInformation({
        eventName: eventNameInputRef.current.value,
        eventDescription: eventDescriptionInputRef.current.value,
        street: streetInputRef.current.value,
        startDate: eventStartDateInputRef.current.value,
        endDate: eventEndDateInputRef.current.value,
        eventGenreId: eventGenreId,
        cityId: selectedCityId,
      })
    );
  };

  return (
    <FormControl onChange={updateEventState}>
      <InputContainer>
        <TextField
          variant="filled"
          label="Event Name"
          inputRef={eventNameInputRef}
          defaultValue={eventData.eventName}
          type="text"
          sx={{ width: "100%" }}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={eventDescriptionInputRef}
          defaultValue={eventData.eventDescription}
          label="Beschreibung"
          type="text"
          multiline
          maxRows={5}
          minRows={5}
          sx={{ width: "100%" }}
        />
      </InputContainer>
      <FormControl>
        <InputContainer>
          <Select
            variant="filled"
            value={eventGenreId}
            sx={{ width: "100%" }}
            onChange={eventTypeSelector}
          >
            <MenuItem value={1}>Standard</MenuItem>
            <MenuItem value={2}>Anderst</MenuItem>
          </Select>
          <FormHelperText>Wähle den Event-Typ aus</FormHelperText>
        </InputContainer>
      </FormControl>
      <InputGroup>
        <TextField
          variant="filled"
          inputRef={eventStartDateInputRef}
          helperText="Start Datum"
          type="date"
          sx={{ width: "48%" }}
        />
        <TextField
          variant="filled"
          inputRef={eventEndDateInputRef}
          helperText="End Datum"
          type="date"
          sx={{ width: "48%" }}
        />
      </InputGroup>
      <InputContainer>
        <TextField
          variant="filled"
          inputRef={streetInputRef}
          defaultValue={eventData.street}
          label="Strasse"
          type="text"
          sx={{ width: "100%" }}
        />
      </InputContainer>
      <InputContainer>
        <Select
          sx={{ width: "100%" }}
          variant="filled"
          value={selectedCityId}
          onChange={citySelectHandler}
        >
          <TextField
            sx={{ width: "100%" }}
            onChange={cityParamHandler}
            variant="filled"
            label="PLZ Stadt"
            type="text"
            helperText="Suche nach Ortschaft"
          />
          {filteredCities.map((city, index) => (
            <MenuItem
              key={index}
              value={city.id}
              onClick={() => setSelectedCityId(city.id)}
            >
              {city.zipCode} {city.cityName}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>PLZ und Ortschaft</FormHelperText>
      </InputContainer>
    </FormControl>
  );
};

export default EventBasicInformation;
