import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: "15rem",
    margin: "1rem 1rem 2rem 2rem",
    height: "100%",
    [theme.breakpoints.down(1466)]: {
      maxWidth: "25%",
      height: "100%",
    },
    [theme.breakpoints.down(1166)]: {
      maxWidth: "80%",
    },
  },
}));
