import { useEffect, useState } from "react";
import { httpContext } from "../../../lib/api/httpContext";
import { DataGrid } from "@mui/x-data-grid";

const HostUserList = () => {
  const [hostUsers, setHostUsers] = useState([]);

  useEffect(() => {
    const getAllHostUsers = async () => {
      const response = await httpContext("hostUsers");

      if (!response.ok) {
        alert("Failed to fetch Host Users");
      }
      if (response.ok) {
        const hostUserData = await response.json();
        setHostUsers(hostUserData);
      }
    };
    getAllHostUsers();
  }, [hostUsers.length]);

  const columns = [
    { field: "hostName", headerName: "Host", width: 200, editable: true },
    { field: "firstName", headerName: "Vorname", width: 180, editable: true },
    { field: "lastName", headerName: "Nachname", width: 180, editable: true },
    { field: "email", headerName: "Email", width: 250, editable: true },
    { field: "gender", headerName: "Geschlecht", width: 120, editable: true },
    {
      field: "roleName",
      headerName: "Berechtigung",
      width: 180,
      editable: true,
    },
    { field: "userStatus", headerName: "Status", width: 180, editable: true },
  ];

  const rows = [];

  if (hostUsers.length > 0) {
    for (const key in hostUsers) {
      rows.push({
        id: hostUsers[key].user.userId,
        firstName: hostUsers[key].user.firstName,
        lastName: hostUsers[key].user.lastName,
        email: hostUsers[key].user.email,
        userStatus: hostUsers[key].user.userStatus,
        gender: hostUsers[key].user.gender,
        roleName: hostUsers[key].user.roleName,
        hostName: hostUsers[key].host.name,
      });
    }
  }

  return (
    <div style={{ marginTop: "2rem" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        autoHeight
      />
    </div>
  );
};

export default HostUserList;
